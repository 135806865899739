import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';

import DyamicForm from '../../component/Form/DyamicForm';
import { FORM_INPUTS } from './constant';
import { CREATE_USER, GET_USER_DATA_BY_ID, UPDATE_USER, getAllCountries_url, getCountryStates_url } from '../../service/Api_URls';
import { getToken, payloadUser } from '../../utility/fnx';
import { Create_User, Getuser, UPDATE_USERs } from '../../api/api';

function InviteUser() {
    const [formInput, setFormInput] = useState(FORM_INPUTS);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const { id } = useParams();
    const token = getToken();
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            organizerName: '',
            type: 'Individual',
            gstStatus: 'Not Registered',
            gstNumber: null,
            country: '',
            state: ''
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            await getCountries();
            if (id) await getUserById(id);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "country" && value.country) {
                getStates(value.country);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const getCountries = async () => {
        try {
            const response = await axios.get(getAllCountries_url);
            if (!response.data.error) {
                setCountries(response.data.data);
                const updatedFormInputs = FORM_INPUTS.map((item) => {
                    if (item.name === "country") return { ...item, options: response.data.data };
                    return { ...item };
                });
                setFormInput(updatedFormInputs);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStates = async (country) => {
        try {
            const response = await axios.post(getCountryStates_url, { country });
            if (!response.data.error) {
                setStates(response.data.data.states);
                const updatedFormInputs = formInput.map((item) => {
                    if (item.name === "state") return { ...item, options: response.data.data.states };
                    return { ...item };
                });
                setFormInput(updatedFormInputs);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const createOrganiser = async (data) => {
        try {
            const payload = payloadUser(data);
            const res = await Create_User(payload);
            if (res.data.success) navigate("/users");
        } catch (error) {
            console.error("Error while creating an organiser", error.message);
        }
    };

    const getUserById = async (id) => {
        try {
            const res = await Getuser(id);
            if (res.success) {
                const { phone, gstStatus, country, state, fullName, type, gstNumber, email } = res.data;
                const newDataObject = {
                    phone: +phone,
                    type,
                    gstStatus,
                    country,
                    state,
                    fullName,
                    gstNumber,
                    email,

                };
                reset(newDataObject);

                // Ensure that the states are fetched and set the value for the state field
                if (country) {
                    await getStates(country);
                    setValue('state', state); // Set the state value if necessary
                }
            }
        } catch (error) {
            console.error("Error while fetching user by ID", error.message);
        }
    };

    const updateInfo = async (values) => {
        try {
            const payload = payloadUser(values);
            const res = await UPDATE_USERs(id, payload);
            if (res.data.success) navigate("/users");
        } catch (error) {
            console.error("Error while updating an organiser", error.message);
        }
    };

    const gstStatus = watch('gstStatus');
    const country = watch('country');

    return (
        <div className="content-body">
            <Form onSubmit={handleSubmit(values => id ? updateInfo(values) : createOrganiser(values))}>
                <Row className='my-3'>
                    <Col md={6}>
                        {formInput.map((item) => {
                            if (item.name === 'gstNumber' && gstStatus !== 'Registered') return null;
                            if (item.name === 'country') {
                                return (
                                    <DyamicForm
                                        key={item.name}
                                        item={{ ...item, options: countries }}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                        watch={watch}
                                    />
                                );
                            }
                            if (item.name === 'state') {
                                return (
                                    <DyamicForm
                                        key={item.name}
                                        item={{ ...item, options: states }}
                                        register={register}
                                        errors={errors}
                                        setValue={setValue}
                                        watch={watch}
                                    />
                                );
                            }
                            return (
                                <DyamicForm
                                    key={item.name}
                                    item={item}
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    watch={watch}
                                />
                            );
                        })}
                    </Col>
                </Row>
                <div className='flex gap-3'>
                    <Button className="SubmitButton btn" type="submit">
                        Submit
                    </Button>
                    <Button className="CancelButton btn btn-default mx-2" onClick={() => navigate('/users')} type="button">
                        Cancel
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default InviteUser;
