import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./modal.scss"
const ConfirmModal = ({ show, handleClose, handleConfirm, deleteDataName }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this {deleteDataName}? This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-cancel' onClick={handleClose}>
                    Cancel
                </Button>
                <Button  className='btn-submit' onClick={handleConfirm}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
