import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { imagurl } from '../../service/Api_URls';

function OrganizerPop({ data, onClose }) {
  return (
    <Modal show onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>KYC Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p><strong>Full Name:</strong> {data?.fullName}</p>
            <p><strong>Company Name:</strong> {data?.CompanyName || 'N/A'}</p>
            <p><strong>Phone:</strong> {data?.phone}</p>
            <p><strong>Country:</strong> {data?.country}</p>
            <p><strong>Address:</strong> {data?.address}</p>
            <p><strong>City:</strong> {data?.city}</p>
            <p><strong>State:</strong> {data?.state}</p>
            <p><strong>Postal Code:</strong> {data?.postalCode}</p>
            <p><strong>GST Number:</strong> {data?.gstNumber}</p>
          </Col>
          <Col>
            <p><strong>PAN Number:</strong> {data?.panNumber}</p>
            <p><strong>Account Number:</strong> {data?.accountnumber}</p>
            <p><strong>IFSC Number:</strong> {data?.ifscnumber}</p>
            <p><strong>Bank Name:</strong> {data?.bankname}</p>
            <p><strong>Account Holder Name:</strong> {data?.accountholdername}</p>
            <p><strong>Account Type:</strong> {data?.accounttype}</p>
            <p><strong>Updated At:</strong> {data?.updatedAt}</p>
            <p><strong>Created At:</strong> {data?.createdAt}</p>
          </Col>
        </Row>
        {data?.MediaObjects?.length > 0 && (
          <Row className="mt-3">
            {data.MediaObjects.map((mediaObject, index) => (
              <Col key={index} xs={6} className="text-center mb-3">
                <p><strong>Image Type:</strong> {mediaObject.objectType}</p>
                <img src={imagurl + mediaObject.imageUrl} alt={`Image ${index}`} className="img-fluid" style={{ maxHeight: '200px' }} />
              </Col>
            ))}
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OrganizerPop;
