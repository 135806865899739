import React, { useState, useEffect } from "react";
import { AddBookingCharges, BookingCharges, FM_Data, updateBooking } from "../../api/api";
import "../../assets/css/style.scss";
import "./FM.scss";
import CustomTable from "../../component/Tables/CustomTable";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import CustomModal from "../../component/CustomModal";
import { toast } from "react-toastify";
import NcustomTable from "../../component/Tables/NcustomTable";

const FinancialManage = () => {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [orginazerList, setOrginazerList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    get_FinancialManagedetail();
  }, [currentPage]);

  const get_FinancialManagedetail = async () => {
    setShowLoader(true);
    const orginazerResponse = await FM_Data();
    if (orginazerResponse?.status === 200) {
      setOrginazerList(orginazerResponse?.data);
      setTotalPages(Math.ceil(orginazerResponse?.data.length / pageSize));
    }
    setShowLoader(false);
  };

  const BookingChargessubmit = async () => {
    setShowLoader(true);
    const orginazerResponse = await BookingCharges(searchText, orginazerList[0]?.id);
    if (orginazerResponse) {
      get_FinancialManagedetail();
      toast.success("Booking charges submitted successfully!");
    }
    setShowLoader(false);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setIsEdit(true);
    setShowEditModal(true);
  };

  const updateBookingCharges = async (data) => {
    setShowLoader(true);
    const response = await updateBooking(editItem.id, { Tax: data.Tax });
    if (response?.status === 200) {
      await get_FinancialManagedetail();
      toast.success("Booking charges updated successfully!");
    } else {
      
      toast.error("Failed to update booking charges.");
    }
    setShowLoader(false);
    setShowEditModal(false);
    reset();
  };

  const addBookingCharge = async (data) => {
    setShowLoader(true);
    const response = await AddBookingCharges({ Tax: data.Tax });
    if (response?.status === 200) {
      await get_FinancialManagedetail();
      toast.success("Booking charge added successfully!");
    } else {
      // console.log(response);
      toast.error(response?.data?.error);
    }
    setShowLoader(false);
    setShowEditModal(false);
    reset();
  };

  const columns = [
    { accessorKey: "Tax", header: "Tax" },
    { accessorKey: "createdAt", header: "Created At", cell: ({ value }) => new Date(value).toLocaleString() },
    { accessorKey: "updatedAt", header: "Updated At", cell: ({ value }) => new Date(value).toLocaleString() },
    {
      accessorKey: "actions",
      header: "Action",
      cell: ({ row }) => (
        <div className='icon-group flex gap-1'>
          <IconButton onClick={() => handleEdit(row)}>
            <Edit fontSize="medium" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side text-gray-500 text-2xl font-medium my-2">
         Financial Management
          </div>
          <div className="right-side"></div>
        </div>
        <div className="orginazer-setup-table my-4">
          <NcustomTable
            btnName="Add Booking Charge"
            rows={orginazerList}
            columns={columns}
            handleAdd={() => {
              setEditItem(null);
              setIsEdit(false);
              setShowEditModal(true);
            }}
          />
        </div>
      </div>
      <CustomModal
        isOpen={showEditModal}
        setOpen={setShowEditModal}
        handleSubmit={handleSubmit}
        onSubmit={isEdit ? updateBookingCharges : addBookingCharge}
        errors={errors}
        register={register}
        title={isEdit ? "Edit Booking Charges" : "Add Booking Charges"}
        reset={reset}
        editItem={editItem}
      />
    </div>
  );
};

export default FinancialManage;
