import React from 'react'
import { imagurl } from '../../service/Api_URls';
import Groupicon from "../../assets/images/Group.png";
function Rightnavbar({orginazerdetails,eventdetail,form}) {
  return (
    <div className="right-bar">
    <div>
        <div>
            <h3 className="text-center right-head"> Events </h3>
        </div>
        <div className="video-channel-list meta scroll-height">
            {/* Dummy eventdata Start */}
            {orginazerdetails &&
                orginazerdetails.map((data, index) => {
                    return (
                        form.values.displayEventName == data?.displayEventName ? <div key={index} className="selected-block block m-auto my-2" onClick={() => eventdetail(data.id)} >
                            <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={data.MediaObjects[0]?.imageUrl ? imagurl + data.MediaObjects[0]?.imageUrl : Groupicon} alt="" /></div>
                            <div className="text-block"><label> {data?.displayEventName}</label></div>
                        </div> : null
                    );
                })}
            {orginazerdetails &&
                orginazerdetails.map((data, index) => {
                    return (
                        form.values.displayEventName == data?.displayEventName ? null :

                            <div className=" block m-auto my-2" key={index} onClick={() => eventdetail(data.id)}>
                                <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={data.MediaObjects[0]?.imageUrl ? imagurl + data.MediaObjects[0]?.imageUrl : Groupicon} alt="" />
                                </div>
                                <div className="text-block"><label>{data.displayEventName}</label></div>
                            </div>
                    );
                })}
        </div>
    </div>
</div>
  )
}

export default Rightnavbar