import React, { useState, useEffect } from 'react';
import ProfileStatistics from '../../component/profile/profile';
import MyCard from './cardview/cardview';
import './style.scss'; // Import CSS file for styling
import { Organizer_Detail } from '../../api/api';

function Detailpage() {

  const organizerDetail = JSON.parse(
    localStorage.getItem("organizerDetail")
  );


  const [orginazerdetails, setorginazerdetails] = useState([]);

  useEffect(() => {
    let queryParams = {
      name: "",
      pageNumber: 1,
      pageSize: 1000
    };
    OrganizerDetail(queryParams, organizerDetail?.createdBy);
  }, []);

  const OrganizerDetail = async (queryParams, id) => {
    const detail = await Organizer_Detail(queryParams, id);

    if (detail.status == 200) {

      setorginazerdetails(detail?.data?.rows)

    }
  }
  return (
    <div className="content-body">
      <div className='container'>
        <div className='row grid col-12'>
        <div className='col-2 p-0 fixed-column'>
  <ProfileStatistics organizerDetail={organizerDetail} />
</div>
<div className='col-9 offset-3  '>
  <MyCard orginazerEventdetails={orginazerdetails} />
</div>

        </div>
      </div>
    </div>

  );
}

export default Detailpage;
