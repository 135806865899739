import React, { useState, useEffect } from 'react';
import prevIcon from '../../assets/images/arrow-prev.svg';
import nextIcon from '../../assets/images/arrow-next.svg';
import downloadIcon from '../../assets/images/download.png';
import { Card } from "react-bootstrap";
import "./reports.scss";
import Chart from 'react-google-charts';

function Reports() {
    const [currentPage1, setCurrentPage1] = useState(0);
    const [totalPages1, setTotalPages1] = useState(0);
    const [numberOfElement1, setNumberOfElement1] = useState(0);
    const [pageSize1, setPageSize1] = useState(4);
    const [orginazerList1, setOrginazerList1] = useState([]);
    const [showLoader1, setShowLoader1] = useState(false);

    const [currentPage2, setCurrentPage2] = useState(0);
    const [totalPages2, setTotalPages2] = useState(0);
    const [numberOfElement2, setNumberOfElement2] = useState(0);
    const [pageSize2, setPageSize2] = useState(4);
    const [orginazerList2, setOrginazerList2] = useState([]);
    const [showLoader2, setShowLoader2] = useState(false);


// Define static data for table 1
const staticData1 = [
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },

    // Add more data items as needed
];

// Define static data for table 2
const staticData2 = [
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },

];
const staticData3 = [
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },
    { organizerName: "Organizer 1", type: "Type A" },
    { organizerName: "Organizer 2", type: "Type B" },

];


    const handlePrev1 = () => {
        if (currentPage1 > 0) {
            setCurrentPage1(currentPage1 - 1);
        }
    };
    
    const handleNext1 = () => {
        if (currentPage1 < totalPages1 - 1) {
            setCurrentPage1(currentPage1 + 1);
        }
    };
    
    const handlePrev2 = () => {
        if (currentPage2 > 0) {
            setCurrentPage2(currentPage2 - 1);
        }
    };
    
    const handleNext2 = () => {
        if (currentPage2 < totalPages2 - 1) {
            setCurrentPage2(currentPage2 + 1);
        }
    };
    
    useEffect(() => {
        // Fetch data for table 1 based on currentPage1
        const startIndex = currentPage1 * pageSize1;
        const endIndex = Math.min(startIndex + pageSize1, staticData1.length);
        const pageData = staticData1.slice(startIndex, endIndex);
    
        // Pad the data with empty elements if the number of items is less than the pageSize1
        const paddedData = [...pageData];
        if (paddedData.length < pageSize1) {
            const emptyItemsCount = pageSize1 - paddedData.length;
            for (let i = 0; i < emptyItemsCount; i++) {
                paddedData.push({ organizerName: "", type: "" }); // You can adjust the empty item structure as needed
            }
        }
    
        setOrginazerList1(paddedData);
        setNumberOfElement1(staticData1.length);
        const totalPagesCount1 = Math.ceil(staticData1.length / pageSize1);
        setTotalPages1(totalPagesCount1);
    
        // Ensure current page remains within bounds after data size changes
        if (currentPage1 >= totalPagesCount1) {
            setCurrentPage1(totalPagesCount1 - 1);
        }
    }, [currentPage1, pageSize1, staticData1]);
    
    
    useEffect(() => {
        // Fetch data for table 2 based on currentPage2
        const startIndex = currentPage2 * pageSize2;
        const endIndex = Math.min(startIndex + pageSize2, staticData2.length);
        const pageData = staticData2.slice(startIndex, endIndex);
    
        setOrginazerList2(pageData);
        setNumberOfElement2(staticData2.length);
        const totalPagesCount2 = Math.ceil(staticData2.length / pageSize2);
        setTotalPages2(totalPagesCount2);
    }, [currentPage2, pageSize2, staticData2]);
    
    const handleSorting = (column) => {
        // Implement sorting logic here
    };
    


 
//  const data = [
//     [
//       "Day",
//       "Guardians of the Galaxy",
//       "The Avengers",
//       "Transformers: Age of Extinction",
//     ],
//     [1, 37.8, 80.8, 41.8],
//     [2, 30.9, 69.5, 32.4],
//     [3, 25.4, 57, 25.7],
//     [4, 11.7, 18.8, 10.5],
//     [5, 11.9, 17.6, 10.4],
//     [6, 8.8, 13.6, 7.7],
//     [7, 7.6, 12.3, 9.6],
//     [8, 12.3, 29.2, 10.6],
//     [9, 16.9, 42.9, 14.8],
//     [10, 12.8, 30.9, 11.6],
//     [11, 5.3, 7.9, 4.7],
//     [12, 6.6, 8.4, 5.2],
//     [13, 4.8, 6.3, 3.6],
//     [14, 4.2, 6.2, 3.4],
//   ];
  
//    const options = {
//     chart: {
//       title: "Box Office Earnings in First Two Weeks of Opening",
//       subtitle: "in millions of dollars (USD)",
//     },
//   };
  const dynamicData = [
    ["Task", "Hours per Day"],
    ["Work", 8],
    ["Eat", 3],
    ["Commute", 2],
    ["Watch TV", 4],
    ["Sleep", 7],
  ];
  
  const dynamicOptions = {
    title: "My Dynamic Activities",
  };
    return (
        <div className="content-body">
            <div className="dashboard">
            <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-3 mb-4">
                            <div className="box">
                                <div className="box-content">
                                    <h1>Heading</h1>
                                    <h2 className='grey-text'>Sub Heading</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <div className="box">
                                <div className="box-content">
                                    <h1>Heading</h1>
                                    <h2 className='grey-text'>Sub Heading</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <div className="box">
                                <div className="box-content">
                                    <h1>Heading</h1>
                                    <h2 className='grey-text'>Sub Heading</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3 mb-4">
                            <div className="box">
                                <div className="box-content">
                                    <h1>Heading</h1>
                                    <h2 className='grey-text'>Sub Heading</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





                <div className="container">
                <div className="report-table">
                                <table className="report-table-list">
                                    <thead>
                                        <tr>
                                            <th className="theads" onClick={() => { handleSorting("name"); }}>
                                                <label>Summary</label>
                                            </th>
                                            <th className="theads">
                                                <label></label>
                                            </th>
                                            <th className="theads" onClick={() => { handleSorting("type"); }}>
                                                <label><img src={downloadIcon} /></label>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="" onClick={() => { handleSorting("name"); }}>
                                                <label>Organizer Name</label>
                                            </th>
                                            <th className="">
                                                <label>Type</label>
                                            </th>
                                            <th className="" onClick={() => { handleSorting("type"); }}>
                                                <label>State</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staticData3 && staticData3.map((item, index) => (
                                            <tr key={index}>
                                                <td className=' '>{item?.organizerName}</td>
                                                <td className=''>{item?.type}</td>
                                                <td className=''>{item?.type}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                          
                            </div>



                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="report-table">
                                <table className="report-table-list">
                                    <thead>
                                    <tr>
                                            <th className="theads" onClick={() => { handleSorting("name"); }}>
                                                <label>Number of Tickets Sold</label>
                                            </th>
                                            <th className="theads">
                                                <label></label>
                                            </th>
                                            <th className="theads" onClick={() => { handleSorting("type"); }}>
                                                <label><img src={downloadIcon} /></label>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="" onClick={() => { handleSorting("name"); }}>
                                                <label>Organizer Name</label>
                                            </th>
                                            <th className="">
                                                <label>Type</label>
                                            </th>
                                            <th className="" onClick={() => { handleSorting("type"); }}>
                                                <label>State</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orginazerList1 && orginazerList1.map((item, index) => (
                                            <tr key={index}>
                                                <td className=' '>{item?.organizerName}</td>
                                                <td className=''>{item?.type}</td>
                                                <td className=''>{item?.type}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {!showLoader1 && orginazerList1.length > 0 ? (
                                <div className="table-pagination">
                                    <div className="pagination-count">
                                        <div className="count">
                                            Page: {(currentPage1 + 1)} of {totalPages1}
                                        </div>
                                        <div className="pagination-arrow">
                                            <a className={`prev ${currentPage1 === 0 ? 'disable' : ''}`} onClick={handlePrev1}><img src={prevIcon} alt="Previous" /></a>
                                            <a  className={`next ${currentPage1 === (totalPages1 - 1) ? 'disable' : ''}`} onClick={handleNext1}><img src={nextIcon} alt="Next" /></a>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="col-md-6">
                            <div className="report-table">
                                <table className="report-table-list">
                                    <thead>
                                    <tr>
                                            <th className="theads" onClick={() => { handleSorting("name"); }}>
                                                <label>Breakup of Tickets Sold</label>
                                            </th>
                                            <th className="theads">
                                                <label></label>
                                            </th>
                                            <th className="theads" onClick={() => { handleSorting("type"); }}>
                                                <label><img src={downloadIcon} /></label>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="" onClick={() => { handleSorting("name"); }}>
                                                <label>Organizer Name</label>
                                            </th>
                                            <th className="">
                                                <label>Type</label>
                                            </th>
                                            <th className="" onClick={() => { handleSorting("type"); }}>
                                                <label>State</label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orginazerList2 && orginazerList2.map((item, index) => (
                                            <tr key={index}>
                                                <td className=' '>{item?.organizerName}</td>
                                                <td className=''>{item?.type}</td>
                                                <td className=''>{item?.type}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {!showLoader2 && orginazerList2.length > 0 ? (
                                <div className="table-pagination">
                                    <div className="pagination-count">
                                        <div className="count">
                                            Page: {(currentPage2 + 1)} of {totalPages2}
                                        </div>
                                        <div className="pagination-arrow">
                                            <a href="javascript:void(0)" className={`prev ${currentPage2 === 0 ? 'disable' : ''}`} onClick={handlePrev2}><img src={prevIcon} alt="Previous" /></a>
                                            <a href="javascript:void(0)" className={`next ${currentPage2 === (totalPages2 - 1) ? 'disable' : ''}`} onClick={handleNext2}><img src={nextIcon} alt="Next" /></a>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className='col-md-6 my-4'>
                        <table className="report-table-list">
                                    <thead>
                                    <tr>
                                            <th className="" onClick={() => { handleSorting("name"); }}>
                                                <label>Organizer Name</label>
                                            </th>
                                           
                                          
                                        </tr>
                                    </thead>

                                    <Chart
    chartType="PieChart"
    data={dynamicData}
    options={dynamicOptions}
    width={"100%"}
    height={"400px"}
  />

                                    </table>

                        </div>
                        <div className='col-md-6 my-4'>
                        <table className="report-table-list">
                                    <thead>
                                        <tr>
                                    
                                            <th className="" onClick={() => { handleSorting("name"); }}>
                                                <label>Organizer Name</label>
                                            </th>
                                           
                                          
                                        </tr>
                                    </thead>

                                                           <Chart
    chartType="PieChart"
    data={dynamicData}
    options={dynamicOptions}
    width={"100%"}
    height={"400px"}
  />

                                    </table>

                        </div>
              
                     
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reports;
