import React, { useEffect } from 'react';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./modal.scss";
import { TextInput } from '@mantine/core';

const CustomModal = ({ editItem, isOpen, setOpen, handleSubmit, onSubmit, errors, register, title, reset }) => {
    useEffect(() => {
        if (editItem) {
            reset(editItem);
        } else {
            reset({ Tax: '' });
        }
    }, [editItem, reset]);

    return (
        <Modal show={isOpen} onHide={() => setOpen(false)} centered>
            <Modal.Body className="ModalBody">
                <Modal.Title className="ModalTitle">{title}</Modal.Title>
                <Form>
                    <Row className='my-3 w-full'>
                        <TextInput 
                            label="Tax" 
                            {...register("Tax", { required: true })} 
                            defaultValue={editItem ? editItem?.Tax : ''} 
                        />
                    </Row>
                    {errors.Tax && <span className="text-danger">This field is required</span>}
                    <div className="flex gap-2">
                        <Button className="SubmitButton btn" onClick={handleSubmit(onSubmit)}>
                            Submit
                        </Button>
                        <Button className="CancelButton btn btn-default mx-2" onClick={() => {
                            reset();
                            setOpen(false);
                        }} type="button">
                            Cancel
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CustomModal;
