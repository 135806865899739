export function getToken() {
    return localStorage.getItem("accessToken");
}

export function payloadUser(data) {
    let { organizerName, email, phone, ...rest } = data;

    let payload = {
        user: {
            fullName: organizerName,
            email,
            phone,
            userRole: 1,
        },
        organizer: {
            ...rest,
            type: rest.type == "on" ? "an Individual" : "a Company",
            gstStatus: rest.gstStatus == "on" ? "Registered" : "Not Registered"
        }
    }

    return payload;
}