import { AddBooking_Charges, BookingChar, BookingCharges_URL, Create_TicketDiscounts_URL, Create_Ticket_URL, Create_User_URL, DeleteOrganizer_list_URL, DeleteUser_URL, Delete_Ticket_URL, Downloaduserdata, EditOrganizer_URL, EventGst_URL, GET_ALL_Discount, GET_ALL_TICKETS, GET_FM_URL, GET_USER_DATA, GET_USER_DATA_BY_ID, Get_current_user, KYC_Data_URL, OrganizerEvent_URL, Organizerlist_URL, UPDATE_USER, Update_TicketDiscounts_URL, Update_Ticket_URL, addEvent_URL, getSingleEvent_URL, restore_User_URL, totalEvent_URL, totalOrganizer_URL, totalSales_URL, updateBooking_Charges, updateEvent_URL } from "../service/Api_URls";
import { deleteInstantData, getInstantData, postInstantData, putInstantData } from "./apiMethod";
export const commonHeaders = {
  "Access-Control-Allow-Origin": "*",
  "ngrok-skip-browser-warning": true,
}
export const Organizer_list = async (queryParams) => {
  let URL = Organizerlist_URL;
  let params = {
    page: queryParams.pageNumber,
    size: queryParams.pageSize,
    name: queryParams.name
  }
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) { return error?.response}
};
export const GET_USERDATA = async (queryParams) => {
  let URL = GET_USER_DATA;
  let params = {
    page: queryParams.pageNumber,
    size: queryParams.pageSize,
    name: queryParams.name
  }
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) { return error?.response}
};

export const DeleteOrganizer_list = async (queryParams) => {
  let URL = DeleteOrganizer_list_URL;
  let params = {
    page: queryParams.pageNumber,
    size: queryParams.pageSize,
    name: queryParams.name
  }
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) { return error?.response}
};
export const Organizer_Detail = async (queryParams, id) => {
  let URL = OrganizerEvent_URL + id;
  let params = {

    page: queryParams.pageNumber,
    size: queryParams.pageSize,
    name: queryParams.name
  }
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) { return error?.response}
};
export const GetcurrentUser = async (queryParams) => {
  let URL = Get_current_user ;
  let params = {

    page: queryParams.pageNumber,
    size: queryParams.pageSize,
    name: queryParams.name,
    startDate: queryParams.startDate,
    endDate: queryParams.endDate
  }
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) { return error?.response}
};
export const Download_userdata = async (queryParams) => {
  let URL = Downloaduserdata ;

  try {
    const response = await getInstantData(URL, {
      params: {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate
      },
      responseType: 'arraybuffer' // Use 'arraybuffer' to handle binary data
    });

    return response; // Return the full response object
  } catch (error) {
    console.error("Error downloading user data:", error);
    return error?.response || { status: 500, data: 'An error occurred' }; // Provide a default error response if none is available
  }
};

export const BookingCharge = async (queryParams) => {
  let URL = BookingChar ;
  let params = {


    startDate: queryParams.startDate,
    endDate: queryParams.endDate
  }
  try {
    const response = await getInstantData(URL, { params });
    return response;
  } catch (error) { return error}
};
export const Event_Detail = async (id) => {
  let URL = getSingleEvent_URL + id;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const restore_User = async (id) => {
  let URL = restore_User_URL + id;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const EventGst = async (id, count) => {
  let URL = EventGst_URL + id;
  let data = {
    "gstPercentage": count
  }
  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const Update_Event = async (id, formdata) => {
  let URL = updateEvent_URL + id;

  try {
    const response = await postInstantData(URL, formdata);
    return response;
  } catch (error) { return error?.response}
};

export const TotalSales = async (id) => {
  let URL = totalSales_URL;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const TotalEvent = async (id) => {
  let URL = totalEvent_URL;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const TotalOrganizer = async (id) => {
  let URL = totalOrganizer_URL;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const KycData = async (queryParams) => {
  let URL = KYC_Data_URL;
  let params = {

    page: queryParams.pageNumber,
    size: queryParams.pageSize,
    name: queryParams.name
  }
  try {
    const response = await getInstantData(URL,{params});
    return response;
  } catch (error) { return error?.response}
};
export const FM_Data = async () => {
  let URL = GET_FM_URL;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};

export const EditOrganizer = async (id, data) => {
  let URL = EditOrganizer_URL + id;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};

export const Add_Event = async (formdata, user_id) => {
  let URL = addEvent_URL + user_id;

  try {
    const response = await postInstantData(URL, formdata);
    return response;
  } catch (error) {
    throw error
  }
};
export const Delete_User= async (user_id) => {
  let URL = DeleteUser_URL + user_id ;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    throw error
  }
};

export const BookingCharges = async ( count ,id) => {
  let URL = BookingCharges_URL + id;
  let data = {
    "bookingpercent": count
  }
  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};

export const GetTickets = async (id) => {
  let URL = GET_ALL_TICKETS+id;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const GetDiscount = async (id) => {
  let URL = GET_ALL_Discount+id;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};
export const Update_TicketDiscounts = async ( id,data) => {
  let URL = Update_TicketDiscounts_URL + id;

  try {
    const response = await putInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const Create_TicketDiscounts = async ( data) => {
  let URL = Create_TicketDiscounts_URL ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const Create_User = async ( data) => {
  let URL = Create_User_URL ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const UPDATE_USERs = async ( id ,data) => {
  let URL = UPDATE_USER+id ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};


export const Getuser = async (id) => {
  let URL = GET_USER_DATA_BY_ID+id;

  try {
    const response = await getInstantData(URL);
    return response;
  } catch (error) { return error?.response}
};




export const Update_Ticket = async ( id , data) => {
  let URL = Update_Ticket_URL + id ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const Create_Ticket = async ( id , data) => {
  let URL = Create_Ticket_URL + id ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const AddBookingCharges = async (  data) => {
  let URL = AddBooking_Charges ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};
export const updateBooking = async ( id , data) => {
  let URL = updateBooking_Charges + id ;

  try {
    const response = await postInstantData(URL, data);
    return response;
  } catch (error) { return error?.response}
};

export const Delete_TicketDiscount= async (user_id) => {
  let URL = Update_TicketDiscounts_URL + user_id ;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    throw error
  }
};
export const Delete_Ticket= async (user_id) => {
  let URL = Delete_Ticket_URL + user_id ;

  try {
    const response = await deleteInstantData(URL);
    return response;
  } catch (error) {
    throw error
  }
};