import React, { useEffect, useState } from "react";
import {
  Create_Ticket,
  Delete_Ticket,
  GetTickets,
  Update_Ticket,
} from "../../api/api";
import moment from "moment";
import CustomTable from "../Tables/CustomTable";
import { Button, SegmentedControl } from "@mantine/core";
import TicketForm from "./TicketForm";
import { IconButton } from "@mui/material";
import { Delete, Edit, Label } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import ConfirmModal from "../confirmDelete";
import NcustomTable from "../Tables/NcustomTable";

function Tickets({ item }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(true);
  const [allTickets, setAllTickets] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [ticketStatus, setTicketStatus] = useState({});

  useEffect(() => {
    GetTicketsData(item?.id);
  }, [item?.id]);

  useEffect(() => {
    const initialStatus = {};
    allTickets.forEach(ticket => {
      initialStatus[ticket.id] = ticket.isticketStatus;
    });
    setTicketStatus(initialStatus);
  }, [allTickets]);

  const handleStatusChange = (ticketId, value) => {
    setTicketStatus((prevStatus) => ({
      ...prevStatus,
      [ticketId]: value,
    }));

    const payload = {
      isticketStatus: value,
    };
    UpdateTicketStatus(ticketId, payload);
  };

  const columns = [
    { accessorKey: "ticketName" },
    { accessorKey: "noOfTickets", header: "Number of Tickets" },
    {
      accessorKey: "isTicketType",
      header: "Ticket Type",
      cell: ({ value }) => <span>{value}</span>,
    },
    {
      accessorKey: "endDate",
      header: "Event Date",
      cell: ({ value }) => <span>{moment(value).format("MMM DD, YYYY")}</span>,
    },
    {
      accessorKey: "isticketStatus",
      header: "Event Status",
      cell: ({ row }) => {
        const ticketId = row.id;
        const statusValue = ticketStatus[ticketId] || "available";
        return (
          <SegmentedControl
            data={[
              { label: "Available", value: "available" },
              { label: "Halt", value: "halt" },
              { label: "Hidden", value: "hidden" },
            ]}
            name={`ticketStatus-${ticketId}`}
            value={statusValue}
            onChange={(value) => handleStatusChange(ticketId, value)}
            className="mx-3 text-base"
          />
        );
      },
    },
    {
      accessorKey: "Action",
      header: "Action",
      cell: ({ row }) => (
        <>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            size="medium"
          >
            <Edit fontSize="lg" />
          </IconButton>
          {/* <IconButton aria-label="tag" size="medium">
            <Label fontSize="lg" />
          </IconButton> */}
          <IconButton
            onClick={() => openDeleteModal(row.id)}
            aria-label="delete"
            size="medium"
          >
            <Delete fontSize="lg" />
          </IconButton>
        </>
      ),
    },
  ];

  const GetTicketsData = async (id) => {
    const ticket = await GetTickets(id);
    if (ticket?.status === 200) {
      setAllTickets(ticket?.data);
    }
  };

  const handleAdd = () => {
    setEditData(null);
    setIsEdit(false);
    setDrawerOpen(true);
  };

  const handleEdit = (row) => {
    setDrawerOpen(true);
    setIsEdit(true);
    setEditData(row);
  };

  const handleCreateTicket = async (id, payload) => {
    try {
      const createStatus = await Create_Ticket(id, payload);
      if (createStatus.status === 200) {
        toast.success(
          typeof createStatus.data.data === 'string' 
            ? createStatus.data.data 
            : "Ticket created successfully",
          { toastId: "create-Ticket-success" }
        );
        GetTicketsData(item?.id);
      } else {
        toast.error(
          typeof createStatus.data.error.message === 'string'
            ? createStatus.data.error.message
            : "Failed to create Ticket",
          { toastId: "create-Ticket-error" }
        );
      }
    } catch (error) {
      console.error("Error creating Ticket:", error);
      toast.error("Failed to create Ticket", {
        toastId: "create-Ticket-error",
      });
    }
  };

  const UpdateTicketStatus = async (id, payload) => {
    try {
      const updateStatus = await Update_Ticket(id, payload);
      if (updateStatus.status === 200) {
        toast.success(
          typeof updateStatus.data.data === 'string'
            ? updateStatus.data.data
            : "Updated successfully",
          { toastId: "update-Ticket-success" }
        );
        GetTicketsData(item?.id);
      } else {
        toast.error(
          typeof updateStatus.data.error.message === 'string'
            ? updateStatus.data.error.message
            : "Failed to update",
          { toastId: "update-Ticket-error" }
        );
      }
    } catch (error) {
      console.error("Error updating:", error);
      toast.error("Failed to update", { toastId: "update-error" });
    }
  };

  const handleDelete = async (id) => {
    try {
      const deleteResponse = await Delete_Ticket(id);
      if (deleteResponse?.status === 200) {
        toast.success(
          typeof deleteResponse.data.data === 'string'
            ? deleteResponse.data.data
            : "Ticket deleted successfully",
          { toastId: "delete-Ticket-success" }
        );
        GetTicketsData(item?.id);
      } else {
        toast.error(
          typeof deleteResponse.data.error.message === 'string'
            ? deleteResponse.data.error.message
            : "Failed to delete Ticket",
          { toastId: "delete-Ticket-error" }
        );
      }
    } catch (error) {
      console.error("Error deleting Ticket:", error);
      toast.error("Failed to delete Ticket", {
        toastId: "delete-Ticket-error",
      });
    }
  };

  const openDeleteModal = (itemId) => {
    setItemToDelete(itemId);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      await handleDelete(itemToDelete);
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };

  return (
    <div className="p-3">
      <TicketForm
        isEdit={isEdit}
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ticketData={editData}
        onSave={isEdit ? UpdateTicketStatus : handleCreateTicket}
        id={item?.id}
      />
      <NcustomTable  btnName={"Add Ticket"} rows={allTickets} columns={columns} handleAdd={handleAdd} />
      {showDeleteModal && (
        <ConfirmModal
          show={showDeleteModal}
          handleClose={closeDeleteModal}
          handleConfirm={confirmDelete}
          deleteDataName={"Ticket"}
        />
      )}
    </div>
  );
}

export default Tickets;
