import { Navigate } from "react-router-dom";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { imagurl } from "./Constant";


export const TimeConverter = (seconds) => {

  if(seconds != undefined){
  const formattedTime = new Date(seconds * 1000).toISOString().substr(11, 8);

  return formattedTime;
  }
};

export function convertTimestampToTime(timestamp) {
  const date = new Date(timestamp);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  return formattedTime;
}


export function capitalizeFirstLetter(string) {
  if (typeof string !== "string") {
    return ""; // If not a string, return an empty string
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatNumber(number) {
  if (isNaN(number) || number < 0) {
    return 'Invalid Input'; // Handle invalid inputs
  }

  const symbols = ['', 'k', 'm', 'b', 't']; // Add more symbols as needed for larger numbers
  const tier = Math.log10(Math.abs(number)) / 3 | 0;

  if (tier === 0) return number;

  const suffix = symbols[tier];
  const scale = Math.pow(10, tier * 3);

  const scaled = number / scale;
  return scaled.toFixed(1) + suffix;
}

export const DateStamp = (timestamp) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(timestamp).toLocaleDateString('en-GB', options);
}


export const isLogin= ()=>{
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken == null) {

    // Redirect to a different route if the access token is not present
    // Use a <Navigate /> component within the render function to handle redirection
    window.location.href = "/login"
    return false;
  } else {
    return true;
  }
  // If the access token is present, do nothing or perform other operations
  
}
export const isLoginstatus= ()=>{
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken == null) {

    // Redirect to a different route if the access token is not present
    // Use a <Navigate /> component within the render function to handle redirection
    // window.location.href = "/login"
    return false;
  } else {
    return true;
  }
  // If the access token is present, do nothing or perform other operations
  
}







export const logoutfunc =()=>{
  localStorage.clear()
  window.location.href = "/"
}

