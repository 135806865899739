import React, { useState, useEffect } from 'react';
import { Organizer_list, TotalEvent, TotalOrganizer, TotalSales } from '../../api/api';
import { Card } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import './dashboard.scss';
import CustomTable from '../../component/Tables/CustomTable';
import OrganizerListing from '../organizerListing/OrganizerListing';
const PAGE_SIZE = 10;
function Dashboard() {
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [TotEvent, setTotEvent] = useState(0);
  const [TotOrganizer, setTotOrganizer] = useState(0);
  const [TotSales, setTotSales] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [numberOfElement, setNumberOfElement] = useState(0);
  const [organizerList, setOrganizerList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const fetchData = async () => {
    setShowLoader(true);
    let queryParams = {
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize: rowsPerPage,
    };

    try {
      const organizerResponse = await Organizer_list(queryParams);
      const totalEvents = await TotalEvent();
      const totalOrganizers = await TotalOrganizer();
      const totalSales = await TotalSales();

      if (organizerResponse?.status === 200) {
        setOrganizerList(organizerResponse?.data.rows);
        setNumberOfElement(organizerResponse?.data.count);
      }
      if (totalEvents?.status === 200) {
        animateCount(0, totalEvents?.data, setTotEvent);
      }
      if (totalOrganizers?.status === 200) {
        animateCount(0, totalOrganizers?.data, setTotOrganizer);
      }
      if (totalSales?.status === 200) {
        animateCount(0, totalSales?.data.totalAmount, setTotSales);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
  
    fetchData();
  }, [currentPage, pageSize, searchText]);

  // useEffect(() => {
  //   const totalPagesCount = Math.ceil(numberOfElement / pageSize);
  //   setTotalPages(totalPagesCount);
  // }, [numberOfElement, pageSize]);

  const animateCount = (start, end, setState) => {
    let current = start;
    const increment = Math.ceil((end - start) / 100);
    const interval = setInterval(() => {
      current += increment;
      if (current >= end) {
        clearInterval(interval);
        current = end;
      }
      setState(current);
    }, 10);
  };

  const cardData = [
    {
      title: 'Organizer',
      stats: TotOrganizer,
      description: 'Total number of organizers.',
    },
    {
      title: 'Events',
      stats: TotEvent,
      description: 'Total number of events created.',
    },
    {
      title: 'Sales',
      stats: TotSales,
      description: 'Total sales through the platform.',
    },
  ];

  const columns = [
    { header: 'Organizer Name', accessorKey: 'organizerName' },
    { header: 'Type', accessorKey: 'type' },
    { header: 'State', accessorKey: 'state' },
    { header: 'GST Status', accessorKey: 'gstStatus' }
  ];

  const rows = organizerList.map(item => ({
    organizerName: item.organizerName,
    type: item.type,
    state: item.state,
    gstStatus: item.gstStatus,
  }));


  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  return (
    <div className="content-body">
      <div className="dashboard">
        <div className="my-5 d-flex justify-between">
          {cardData?.map((data, index) => (
            <Card
              className="w-[30%] p-0 space-y-4 border border-gray-200 rounded-lg hover:shadow-md transition-shadow duration-300 ease-in-out hover:-mt-1"
              key={index}
            >
              <Card.Header className="text-xl px-2 py-1 w-full bg-gray-100 font-medium text-gray-700">
                {data?.title}
              </Card.Header>
              <Card.Body className="p-2 text-center space-y-3">
                <div className="text-3xl font-medium text-blue-900">
                  {data?.stats}
                </div>
                <div className="text-base text-gray-600 h-12">{data?.description}</div>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className="top-content">
          <div className="left-side mt-4 text-gray-500 text-2xl font-medium">
            Users
          </div>
          <div className="right-side"></div>
        </div>
        <div className="my-2 Dashboard table">
        <CustomTable
            btnName={""}
            rows={organizerList}
            columns={columns}
            handleAdd={null} 
            count={numberOfElement}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            filterText={searchText}
            setFilterText={setSearchText}
          />
        </div>
      
      </div>
    </div>
  );
}

export default Dashboard;
