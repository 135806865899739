import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import DyamicForm from '../../component/Form/DyamicForm';
import { FORM_INPUTS } from './constant';
import { CREATE_USER, GET_USER_DATA_BY_ID, UPDATE_USER, getAllCountries_url, getCountryStates_url } from '../../service/Api_URls';
import { getToken, payloadUser } from '../../utility/fnx';
import { Create_User, Getuser, UPDATE_USERs } from '../../api/api';

const InviteUser = ({ id, closeDrawer }) => {
    const [formInput, setFormInput] = useState(FORM_INPUTS);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const token = getToken();
    const { register, handleSubmit, watch, formState: { errors }, reset, setValue } = useForm({
        defaultValues: {
            organizerName: '',
            type: 'Individual',
            gstStatus: 'Not Registered',
            gstNumber: null,
            country: '',
            state: ''
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            await getCountries();
            if (id) await getUserById(id);
        };
        fetchData();
    }, [id]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "country" && value.country) {
                getStates(value.country);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const getCountries = async () => {
        try {
            const response = await axios.get(getAllCountries_url);
            if (!response.data.error) {
                setCountries(response.data.data);
                const updatedFormInputs = FORM_INPUTS.map((item) => {
                    if (item.name === "country") return { ...item, options: response.data.data };
                    return { ...item };
                });
                setFormInput(updatedFormInputs);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStates = async (country) => {
        try {
            const response = await axios.post(getCountryStates_url, { country });
            if (!response.data.error) {
                setStates(response.data.data.states);
                const updatedFormInputs = formInput.map((item) => {
                    if (item.name === "state") return { ...item, options: response.data.data.states };
                    return { ...item };
                });
                setFormInput(updatedFormInputs);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const createOrganiser = async (data) => {
        try {
            const payload = payloadUser(data);
            const res = await Create_User(payload);
            if (res.data.success) closeDrawer();
        } catch (error) {
            console.error("Error while creating an organiser", error.message);
        }
    };

    const getUserById = async (id) => {
        try {
            const res = await Getuser(id);
            if (res.success) {
                const { phone, gstStatus, country, state, fullName, type, gstNumber, email } = res.data;
                const newDataObject = {
                    phone: +phone,
                    type,
                    gstStatus,
                    country,
                    state,
                    fullName,
                    gstNumber,
                    email,
                };
                reset(newDataObject);

                if (country) {
                    await getStates(country);
                    setValue('state', state);
                }
            }
        } catch (error) {
            console.error("Error while fetching user by ID", error.message);
        }
    };

    const updateInfo = async (values) => {
        try {
            const payload = payloadUser(values);
            const res = await UPDATE_USERs(id, payload);
            if (res.data.success) closeDrawer();
        } catch (error) {
            console.error("Error while updating an organiser", error.message);
        }
    };

    const gstStatus = watch('gstStatus');
    const country = watch('country');

    return (
        <div className="p-4 bg-white rounded-lg shadow-lg">

            <div className='font-semibold text-gray-700 text-2xl '>
                User Details
            </div>
            <div className="grid grid-cols-2 gap-2 p-5 border border-gray-200 my-5 min-h-[60vh]">
                {formInput.map((item) => {
                    if (item.name === 'gstNumber' && gstStatus !== 'Registered') return null;

                    return (
                        <div className='my-2' key={item.name}>
                            <label className="block text-gray-700 text-lg font-bold mb-2">{item.title}</label>
                            <div className="text-gray-900 text-base">{watch(item.name)}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default InviteUser;
