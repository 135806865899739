import React, { useState, useEffect } from 'react';
import prevIcon from '../../assets/images/arrow-prev.svg';
import nextIcon from '../../assets/images/arrow-next.svg';
import { KycData } from '../../api/api';
import OrganizerPop from './OrganizerPop'; // Ensure this import is correct
import EYE_ICON from '../../assets/images/eye.svg';
import CustomTable from '../../component/Tables/CustomTable';


function KycDetailpage() {
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [numberOfElement, setNumberOfElement] = useState(0);

  useEffect(() => {
    get_kycdetail();
  }, [currentPage, searchText,rowsPerPage]);

  const get_kycdetail = async () => {
    let queryParams = {
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize: rowsPerPage,
 
    };
    const res = await KycData(queryParams);
    if (res?.status == 200) {
      setData(res?.data.rows);
      setNumberOfElement(res?.data?.count);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const openModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const columns = [
    { header: "Company Name", accessorKey: "CompanyName" },
    { header: "Full Name", accessorKey: "fullName" },
    { header: "City", accessorKey: "city" },
    { header: "Phone", accessorKey: "phone" },
    { header: "Country", accessorKey: "country" },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ value, row }) => (
        <a onClick={() => openModal(row)}><img src={EYE_ICON} alt="View" /></a>
      ),
    },
  ];

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page when changing rows per page
  };
  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side text-gray-500 text-2xl font-medium my-2">
       KYC Detail
          </div>
          <div className="right-side"></div>
        </div>
        <div className="orginazer-setup-table">
       
   <CustomTable
         btnName={""}
          handleAdd={null}
          isLoading={showLoader}
          rows={data}
            columns={columns}
          count={numberOfElement}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          filterText={searchText}
          setFilterText={setSearchText}
        />
        </div>
      </div>
      {showModal && <OrganizerPop data={modalData} onClose={closeModal} />}
    </div>
  );
}

export default KycDetailpage;
