import React, { useState } from 'react';
import "../../assets/css/style.scss";
import "./searchBar.scss";
import searchIcon from "../../assets/images/search.svg";

function SearchBar(props) {
  const [searchValue, setSearchValue] = useState(props.searchValue);

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    if (props.keyPress) {
      props.searchOutput(value);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && props.keyEnter) {
      props.searchOutput(searchValue);
    }
  }

  const handleButtonClick = () => {
    props.searchOutput(searchValue);
  }

  return (
    <div className='search-bar'>
      <input 
        type='text' 
        placeholder={props.placeholder} 
        value={searchValue} 
        onKeyDown={handleKeyDown} 
        onChange={handleSearchChange} 
      />
      <button className='search-btn' onClick={handleButtonClick}><img src={searchIcon} /></button>
    </div>
  )
}

SearchBar.defaultProps = {
  searchValue: "",
  searchOutput: () => {},
  keyEnter: true,
  keyPress: true
};

export default SearchBar;
