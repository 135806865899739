import React, { useState, useEffect } from 'react';
import SearchBar from '../../component/search-bar/searchBar';
import { Delete_User, DeleteOrganizer_list, Organizer_list, restore_User } from '../../api/api';
import "../../assets/css/style.scss";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { getData } from '../../api/apiMethod';
import { DeleteOrganizer_list_URL, GET_USER_DATA } from '../../service/Api_URls';
import { getToken } from '../../utility/fnx';
import EYE_ICON from '../../assets/images/eye.svg';
import editIcon from '../../assets/images/edit-outline.svg';
import deleteIcon from '../../assets/images/delete_forever.png';
import CustomTable from '../../component/Tables/CustomTable';
import { Delete, Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import moment from 'moment';

const PAGE_SIZE = 10;

const DeleteUser = () => {
  const [searchText, setSearchText] = useState("");
  const [orginazerList, setOrginazerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [numberOfElement, setNumberOfElement] = useState(0);
  const [showLoader, setshowLoader] = useState(false);
  const [data, setData] = useState([]);
  const [restoringId, setRestoringId] = useState(null); // Track the restoring row
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const columns = [
    { accessorKey: 'emailId', header: 'Email' },
    { accessorKey: 'id', header: 'ID' },
    {
      accessorKey: 'deletedAt', header: 'deletedAt',
      cell: ({ value }) => moment(value).format("DD/MM/YYYY, hh:mm a"),
    },
    {
      accessorKey: "actions",
      header: "Action",
      cell: ({ row }) => (
        <div className='icon-group flex gap-1'>
          <IconButton
            onClick={() => restoreUser(row.id)}
            aria-label="restore"
            size="medium"
            className={`transition-transform ${restoringId === row.id ? 'animate-spin' : ''}`}
          >
            <RestoreIcon fontSize="medium" />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const totalPagesCount = Math.ceil(numberOfElement / PAGE_SIZE);
    setTotalPages(totalPagesCount);
  }, [numberOfElement]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(0);
  };

  const restoreUser = async (id) => {
    setRestoringId(id); // Set the restoring row
    const restore = await restore_User(id);
    if (restore?.status === 200) {
      await getUserData();
    } else {
      alert(DeleteUser?.error);
    }
    setRestoringId(null); // Reset the restoring row
  };

  const getUserData = async () => {
 
    let params = {
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize: rowsPerPage,
     
    };
    try {
      const res = await DeleteOrganizer_list(params);
      if (res?.success){ setData(res?.data.rows)

        setNumberOfElement(res?.data?.count);



      };
    } catch (error) {
      console.log("Error while fetching the data: ", error.message);
    }
  };

  useEffect(() => {
    getUserData();
  }, [currentPage, searchText,rowsPerPage]);

  const handleDateFilter = () => {
    setCurrentPage(0);
    getUserData();
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page when changing rows per page
  };
  return (
    <div className="content-body">
    <div className="dashboard-content">
      <div className="top-content">
        <div className="left-side  text-gray-500 text-2xl font-medium my-2">
          User Management
        </div>
        <div className="right-side"></div>
      </div>
      <div className='orginazer-setup-header my-2 '>
        {/* <div className='orginazer-setup-search w-50'>
          <SearchBar
            placeholder="Search by user name"
            keyPress={false}
            searchOutput={handleSearch}
          />
        </div> */}

        {/* <div className='orginazer-setup-button my-2'>
          <Link to="/users/add-user">
            Invite User
          </Link>
        </div> */}
      </div>
      <div className='orginazer-setup-table'>
  
<CustomTable
         btnName={""}
          handleAdd={null}
          isLoading={showLoader}
          rows={data}
          columns={columns}
          count={numberOfElement}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          filterText={searchText}
          setFilterText={setSearchText}
        />
      </div>
    </div>
  </div>
  );
};

export default DeleteUser;
