import React, { useState, useEffect } from "react";
import "./Eventdetail.scss";
import { Event_Detail, Organizer_Detail, Update_Event } from "../../api/api";

import { imagurl } from "../../service/Api_URls";

import { useForm } from "@mantine/form";


import Rightnavbar from "../Rightnavbar";
import Eventedit from "./Eventedit";
import { Button, Tabs } from "@mantine/core";

import Tickets from "../tickets/Tickets";
import Discount from "../Discount/Discount";

function Eventdetail() {
    const [eventDataFromStorage, seteventDataFromStorage] = useState(
        JSON.parse(localStorage.getItem("eventdetail"))
    );
    const [editMode, setEditMode] = useState(false);
    const [imageShow, setimageurl] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const organizerDetail = JSON.parse(localStorage.getItem("organizerDetail"));
    const [orginazerdetails, setorginazerdetails] = useState([]);
    const [longDescription, setLongDescription] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [editorValue, seteditorValue] = useState("");

    const form = useForm({
        initialValues: {
            eventName: "",
            displayEventName: "",
            currencySymbol: "",
            eventStatus: "",
            eventUrl: "",
            isLive: "",
            isVisibility: "",
            endDate: "",
            endTime: "",
            startDate: "",
            startTime: "",
            timeZone: "",
            city: "",
            latitude: "",
            longitude: "",
            streetLane1: "",
            streetLane2: "",
            venueName: "",
        },
    });

    useEffect(() => {
        let queryParams = {
            name: "",
            pageNumber: 1,
            pageSize: 1000,
        };
        OrganizerDetail(queryParams, organizerDetail?.createdBy);
    }, []);

    const OrganizerDetail = async (queryParams, id) => {
        const detail = await Organizer_Detail(queryParams, id);
        if (detail?.status == 200) {
            setorginazerdetails(detail?.data?.rows);
            seteventDataFromStorage(JSON.parse(localStorage.getItem("eventdetail")));
        }
    };

    useEffect(() => {
        const eventDetail = JSON.parse(localStorage.getItem("eventdetail"));
        if (eventDetail) {
            form.setValues({
                eventName: eventDetail?.eventName || "",
                displayEventName: eventDetail?.displayEventName || "",
                currencySymbol: eventDetail?.currencySymbol || "",
                eventStatus: eventDetail?.eventStatus || "",
                eventUrl: eventDetail?.eventUrl || "",
                isLive: eventDetail?.isLive || "",
                isVisibility: eventDetail?.isVisibility || "",
                endDate: eventDetail?.endDate || "",
                endTime: eventDetail?.endTime || "",
                startDate: eventDetail?.startDate || "",
                startTime: eventDetail?.startTime || "",
                timeZone: eventDetail?.timeZone || "",
                city: eventDetail?.EventLocation?.city || "",
                latitude: eventDetail?.EventLocation?.latitude || "",
                longitude: eventDetail?.EventLocation?.longitude || "",
                streetLane1: eventDetail?.EventLocation?.streetLane1 || "",
                streetLane2: eventDetail?.EventLocation?.streetLane2 || "",
                venueName: eventDetail?.EventLocation?.venueName || "",
            });
            setimageurl(eventDetail?.MediaObjects[0]?.imageUrl || "");
            setSelectedFile(eventDetail?.MediaObjects[0]?.imageUrl || "");
            setLongDescription(eventDetail?.longDescription || "");
            setShortDescription(eventDetail?.shortDescription || "");
            seteditorValue(eventDetail?.longDescription || eventDetail?.shortDescription || "")
        }
    }, []);

    const handleEdit = () => {
        setEditMode(true);
    };

    const handleUpdate = () => {
        if (!imageShow && editMode) {
            alert("Please upload an image.");
            return;
        } else {
            UpdateDetail(eventDataFromStorage?.id);
            setEditMode(false);
        }
    };

    const eventdetail = async (id) => {
        const eventdetail = await Event_Detail(id);
        if (eventdetail?.status == 200) {
            localStorage.setItem("eventdetail", JSON.stringify(eventdetail?.data));
            window.location.href = "/eventdetail";
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setimageurl(URL.createObjectURL(file));
    };

    const UpdateDetail = async (id) => {
        const formData = new FormData();
        Object.keys(form.values).forEach((key) => {
            formData.append(key, form.values[key]);
        });
        formData.append("longDescription", longDescription);
        formData.append("shortDescription", shortDescription);
        formData.append("objectType", "DesktopCover");

        let imagurl = imagurl + selectedFile;
        if (!selectedFile?.name) {
            try {
                const response = await fetch(imagurl);
                const blob = await response.blob();
                const urlParts = selectedFile.split("/");
                const fileName = urlParts[urlParts.length - 1];
                const file = new File([blob], fileName, { type: blob.type });
                formData.append("desktopcover", file);
                imagurl = imagurl + selectedFile;
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        } else {
            formData.append("desktopcover", selectedFile);
        }

        const updatedetail = await Update_Event(id, formData);
        if (updatedetail?.status == 200) {
            let queryParams = {
                name: "",
                pageNumber: 1,
                pageSize: 1000,
            };
            OrganizerDetail(queryParams, organizerDetail?.createdBy);
            await eventdetail(eventDataFromStorage.id);
        }
    };

    const delete_img = () => {
        setimageurl(null);
    };

    const redirectToForm = (id,createdBy) => {
        if(id){
            window.open(` https://sportifi.in/event-login/?id=${id}&eventid=${createdBy}`, '_blank');
        }
            
     
    }
   
    return (
        <div className="content-body bg-light ">
            <div className="row">
              
                <div className="video-title my-3">
                    {eventDataFromStorage?.displayEventName}
                </div>
           
             
               
                <div className="breadcrumbs"></div>
                <div className="col-10">
                   
                    <Tabs variant="outline" defaultValue="Detail">
                        <Tabs.List className="tablisting">
                            <Tabs.Tab value="Detail" >
                                Detail
                            </Tabs.Tab>
                            <Tabs.Tab value="Ticket" >
                                Ticket
                            </Tabs.Tab>
                            <Tabs.Tab value="Discount" >
                            Discount
                            </Tabs.Tab>
                            <Tabs.Tab value="Form" >
                            Form
                            </Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value="Detail">
                            <Eventedit form={form} editMode={editMode} editorValue={editorValue} longDescription={longDescription} setLongDescription={setLongDescription} shortDescription={shortDescription} setShortDescription={setShortDescription} imageShow={imageShow} delete_img={delete_img} handleFileChange={handleFileChange} handleEdit={handleEdit} handleUpdate={handleUpdate} />
                        </Tabs.Panel>

                        <Tabs.Panel value="Ticket">
                            <Tickets item={eventDataFromStorage} />
                        </Tabs.Panel>

                        <Tabs.Panel value="Discount">
                            <Discount item={eventDataFromStorage} />
                        </Tabs.Panel>
                        <Tabs.Panel value="Form">
  <div className="flex md:h-[60vh] justify-center items-center">
    <Button
size="xl"
      variant="outline"
      className="items-center text-base font-medium w-25  "
      color="#0b3d6a"
      onClick={() => redirectToForm(eventDataFromStorage?.id, eventDataFromStorage?.createdBy)}
    >
      Create/Edit Forms
    </Button>
  </div>
</Tabs.Panel>

                    </Tabs>


                </div>
                <div className="col-2">
              
                    <Rightnavbar orginazerdetails={orginazerdetails} eventdetail={eventdetail} form={form} />
                </div>
            </div>

        </div>
    );
}

export default Eventdetail;
