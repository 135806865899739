import axios from "axios";
// import { refreshTokenAPI } from "../api/api";

const instance = axios.create({
    baseURL: "http://:3000",
    withCredentials: false,
});

instance.interceptors.request.use((config) => {
    // config.data = {
    //     "refreshToken": localStorage.getItem("refreshToken")
    // }
    config.headers["Authorization"] = `${localStorage.getItem("accessToken")}`;


    return config;
});

const maxRetryAttempts = 1;
let attemts = 0;

instance.interceptors.response.use(
    function (response) {
        if (response.config.url === "auth/refresh") {
            localStorage.setItem("accessToken", response.data.accessToken);
        }
        return response;
    },
    async function (error) {
        const config = error.config;

        if (error.response && error.response.status === 401 && error.response.data.error == "Unauthorized") {
            window.location.href = "/"

        } else if (error.response.status === 401 && error.response.data.message == "Refresh token expired") {
            // refreshTokenAPI();
            // console.log("err");
            // localStorage.clear()t;
            // window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default instance;