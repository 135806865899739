

export const columns = [
    { key: 'id', title: 'ID' },
    { key: 'organizerName', title: 'Organizer Name' },
    { key: 'email', title: 'Email' },
    { key: 'country', title: 'Country' },
    { key: 'state', title: 'State' },
    { key: 'type', title: 'Organizer Type' },
    { key: 'gstStatus', title: 'GST Registration Status ' }
];

export const actions = [
    { key: "delete", status: true },
    { key: "edit", status: true },
];

const organizer_type = [
    { label: "an Individual", value: "an Individual" },
    { label: "a Company", value: "a Company" },
];

const gstStatus = [
    { label: "Registered", value: "Registered" },
    { label: "Not Registered", value: "Not Registered" }
];

export const FORM_INPUTS = [
    { key: 'email', title: 'Email', name: "email", required: true, type: "input-box" },
    { key: 'number', title: 'Phone Number', name: "phone", required: false, type: "input-box" },
    { key: 'select', title: 'Country', name: "country", required: true, type: "select-box" },
    { key: 'select', title: 'State', name: "state", required: true, type: "select-box" },
    { key: 'text', title: 'Organizer Name', name: "fullName", required: true, type: "input-box" },
    { key: 'radio', title: 'Organizer Type', name: "type", required: true, type: "radio", options: organizer_type },
    { key: 'radio', title: 'GST Registration Status', name: "gstStatus", required: true, type: "radio", options: gstStatus },
    { key: 'number', title: 'GSTIN Number', name: "gstNumber", required: true, type: "input-box" },
];