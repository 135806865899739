import { MantineProvider } from '@mantine/core';
import { ToastContainer } from 'react-toastify';
import './App.css';
import AppRoutes from './routes/appRouting';
import 'react-toastify/dist/ReactToastify.css';
import '@mantine/core/styles.css';
import './index.css';

function App() {
  return (
    <MantineProvider>
      <AppRoutes />
      <ToastContainer />
    </MantineProvider>
  );
}

export default App;
