import React from 'react'
import NewUser from './NewUser'

function AdminDashboard() {
  return (
  

<NewUser />


  )
}

export default AdminDashboard