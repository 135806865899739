import React from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const DyamicForm = ({ item: { key, title, required, type, name, options }, register, errors, setValue, watch }) => {
    switch (type) {
        case "input-box":
            return (
                <div className="mb-3">
                    <Form.Label>{title} {required ? "*" : ""}</Form.Label>
                    <Form.Control
                        type={key}
                        name={name}
                        {...register(name, {
                            required: required ? `${title} field is required.` : false,
                        })}
                    />
                    {errors[name] && (
                        <Form.Text className="text-danger">
                            {errors[name].message}
                        </Form.Text>
                    )}
                </div>
            );
        
        case "select-box":
            return (
                <div className="mb-3">
                    <Form.Label>{title} {required ? "*" : ""}</Form.Label>
                    <Form.Select
                        {...register(name, {
                            required: required ? `${title} field is required.` : false,
                        })}
                        value={watch(name) || ""}
                        onChange={(e) => setValue(name, e.target.value)}
                    >
                        <option value="">{`Select a ${title}`}</option>
                        {options && options.map((option) => (
                            <option key={option.value} value={option.value}>{option.name}</option>
                        ))}
                    </Form.Select>
                    {errors[name] && (
                        <Form.Text className="text-danger">
                            {errors[name].message}
                        </Form.Text>
                    )}
                </div>
            );

        case "radio":
            return (
                <div className="mb-3">
                    <Form.Label className='font-weight-bold'>{title} {required ? "*" : ""}</Form.Label>
                    <div>
                        {options && options.map((option) => (
                            <Form.Check
                                inline
                                key={option.value}
                                label={option.label} // Changed from option.value to option.label for display
                                type={type}
                                name={name}
                                value={option.value} // Use option.value here
                                id={`inline-radio-${option.value}`} // Unique ID for each radio button
                                checked={watch(name) === option.value} // Check if this radio button is selected
                                onChange={(e) => setValue(name, e.target.value)}
                                {...register(name, {
                                    required: required ? `${title} field is required.` : false,
                                })}
                            />
                        ))}
                    </div>
                    {errors[name] && (
                        <Form.Text className="text-danger">
                            {errors[name].message}
                        </Form.Text>
                    )}
                </div>
            );

        default:
            return null;
    }
};

export default DyamicForm;
