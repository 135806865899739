import React, { useState, useEffect } from 'react';
import './profile.scss'; // Import CSS file for styling
import profile from '../../assets/images/zee.png';
import { imagurl } from '../../service/Api_URls';
import { EventGst } from '../../api/api';

import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
const Profile = ({ organizerDetail }) => {

  let orginazerdetail = organizerDetail

  const navigate = useNavigate();

  const [selectedValue, setSelectedValue] = useState('');
  const [options, setOptions] = useState([9, 18, 30]);



  useEffect(() => {
    // Make API call when selectedValue changes
    if (selectedValue !== '') {
      fetchData();
    }
  }, [selectedValue]);

  const fetchData = async () => {
    try {
      const response = await EventGst(orginazerdetail?.createdBy, selectedValue)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };


  return (
    <div className="profile-container">
      <div className="profile-content">
        <img src={orginazerdetail?.coverPhotoUrl ? imagurl + orginazerdetail?.coverPhotoUrl : profile} alt="Profile" className="profile-image my-2" />
        <h2 className="profile-name py-3">{orginazerdetail?.organizerName}</h2>
        <div className="profile-contact">
          <div className="profile-contact-item">GST Status | {orginazerdetail?.gstStatus}</div>
        </div>
        <p className="profile-about "> Type | {orginazerdetail?.type}</p>
        <p className="profile-about "> {orginazerdetail?.state} | {orginazerdetail?.country}</p>
        <Button variant='outline'  className='w-75 ' color='#0b3d6a' onClick={() => navigate("/add-event")} >Add Event</Button>
        <div className='py-3'>

          {/* <div className="profile-right d-flex ">
            <div className='p-2'><b> GST Percentage -</b></div>

            <div className="dropdown ">


              <select className="form-control" onChange={handleSelectChange} value={selectedValue}>
                <option value="">Select...</option>
                {options.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
          </div> */}


        </div>

      </div>

    </div>
  );
};

export default Profile;
