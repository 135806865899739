import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import { Event_Detail, Organizer_Detail } from "../../../api/api";
import { imagurl } from "../../../service/Api_URls";
import profile from "../../../assets/images/profile.jpg";
import SearchBar from "../../../component/search-bar/searchBar";
import "./card.scss";
import editIcon from '../../../assets/images/edit-outline.svg';

const MyCard = ({ orginazerEventdetails }) => {
  const navigate = useNavigate();
  const organizerDetail = JSON.parse(localStorage.getItem("organizerDetail"));

  const [data, setdata] = useState(orginazerEventdetails);

  // const data = [
  //     {
  //       id: 1,
  //       imageSrc: profile,
  //       title: 'Sample Title 1',
  //       message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //       rating: 4.5
  //     },
  //     {
  //         id: 1,
  //         imageSrc: profile,
  //         title: 'Sample Title 1',
  //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //         rating: 4.5
  //       }, {
  //         id: 1,
  //         imageSrc: profile,
  //         title: 'Sample Title 1',
  //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //         rating: 4.5
  //       }, {
  //         id: 1,
  //         imageSrc: profile,
  //         title: 'Sample Title 1',
  //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //         rating: 4.5
  //       }, {
  //         id: 1,
  //         imageSrc: profile,
  //         title: 'Sample Title 1',
  //         message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  //         rating: 4.5
  //       },

  //     // Add more objects as needed
  //   ];

  useEffect(() => {
    setdata(orginazerEventdetails);
  }, [orginazerEventdetails]);
  const handleSearch = (event) => {
    let queryParams = {
      name: event,
      pageNumber: 1,
      pageSize: 1000,
    };
    Organizerevent(queryParams, organizerDetail?.createdBy);
  };

  const Organizerevent = async (queryParams, id) => {
    const detail = await Organizer_Detail(queryParams, id);

    if (detail.status == 200) {
      setdata(detail?.data?.rows);
    }
  };
  const eventdetail = async (id) => {
    const eventdetail = await Event_Detail(id);
    if (eventdetail.status == 200) {
      localStorage.setItem("eventdetail", JSON.stringify(eventdetail?.data));
      // window.location.href = "/eventdetail"
      navigate("/eventdetail");
    }

  };
  return (
    <div className="container">
      <div className="w-50" style={{ marginLeft: "auto" }}>
        <SearchBar
          placeholder="Search by organizer name"
          keyPress={false}
          searchOutput={(value) => handleSearch(value)}
        />
      </div>

      <div className="grid col-12">
        {data.map((item) => (
          <div className="col-4">
            <Card className="Cardone"
              key={item.eventName}
            >
              <Card.Img variant="top" className="card-image" src={item.MediaObjects[0]?.imageUrl ? imagurl + item.MediaObjects[0]?.imageUrl : profile} />
              <div className="edit-card-icon" onClick={() => eventdetail(item.id)}>
                <img
                  src={editIcon}
                  style={{ width: "20px" }}
                />
              </div>
              <Card.Body>
                <Card.Title>{item.displayEventName}</Card.Title>
                <Card.Text>
                  <p>
                    {" "}
                    {item.startDate} | {item.startTime}
                  </p>
                  <p> location - {item.streetLane1}</p>

                  <Card.Title>{item?.CreateTickets[0]?.isTicketType} {item?.CreateTickets[0]?.isTicketType == "Paid" && "| " + item?.CreateTickets[0]?.ticketPrice + item?.CreateTickets[0]?.currencySymbol}  </Card.Title>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyCard;
