import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import Layout from "../Layout/Layout";
import Detailpage from "../pages/detailpage/Detailpage";
import Login from "../pages/login/Login";

import Eventdetail from "../component/Eventdetail/Eventdetail";
import OrganizerListing from "../pages/organizerListing/OrganizerListing";
import AddEvent from "../pages/add Event/AddEvent";
import UserManagement from "../pages/UserManagement";
import Reports from "../pages/report/reports";
import InviteUser from "../pages/UserManagement/InviteUser";

import KycDetailpage from "../pages/kycpage/KycDetailpage";
import FinancialManage from "../pages/FinanManage/FinancialManage";
import DeleteUser from "../pages/DeleteUser";
import AdminDashboard from "../pages/AdminDashboard";



// import ViewershipComponent from './ViewershipComponent';
// import DashboardComponent from './DashboardComponent';
// import DashbordChannelComponent from './DashbordChannelComponent';
// import ChannelActionsComponent from './ChannelActionsComponent';
// import SchedulingComponent from './SchedulingComponent';
// import ChannelSetupComponent from './ChannelSetupComponent';
// import ChannelCreationComponent from './ChannelCreationComponent';
// import ChannelUploadComponent from './ChannelUploadComponent';

function AppRoutes() {
  const [eventDataFromStorage, seteventDataFromStorage] = useState(
    JSON.parse(localStorage.getItem("userdetail"))
);

// console.log("eventDataFromStorage", eventDataFromStorage?.email);

  return (
    <Router>
      <Routes>
        {/* <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} /> */}
        <Route path="/" element={<Login />} />
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/detailpage" element={<Detailpage />} />
          <Route path="/add-event" element={<AddEvent />} />
          <Route path="/eventdetail" element={<Eventdetail />} />
          <Route path="/organizer-listing" element={<OrganizerListing />} />
          <Route path="/users" element={<UserManagement />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/users/add-user" element={<InviteUser />} />
          <Route path="/users/:id" element={<InviteUser />} />
          <Route path="/financial-management" element={<FinancialManage />} />
          <Route path="/kyc" element={<KycDetailpage />} />
          <Route path="/delete-user" element={<DeleteUser />} />
         { eventDataFromStorage?.email == "admin@test.com" &&   <Route path="/admin-user-dashboard" element={<AdminDashboard />} />}
        
        </Route>

        {/* <Route exact path="/login" component={LoginComponent} />
    {/* Define other routes here */}

      </Routes>
    </Router>
  );
}

export default AppRoutes;
