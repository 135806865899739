import React, { useState } from "react";
import "./addevent.scss"; // Import CSS file for styling
import { Badge, Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Add_Event } from "../../api/api";
import { useNavigate } from "react-router-dom";
import AddTicket from "../../component/AddTicket";

function generateUniqueId() {
  // Generate a unique ID using the current timestamp and a random number
  const timestamp = new Date().getTime();
  const randomNum = Math.floor(Math.random() * 10000); // You can adjust this range as needed
  const uniqueId = `${timestamp}-${randomNum}`;
  return uniqueId;
}

const AddEvent = () => {
  const allTimezones = moment.tz.names();
  const timezones = allTimezones.map((timezone) => {
    const timezoneOffset = moment.tz(timezone).utcOffset();
    const sign = timezoneOffset >= 0 ? "+" : "-";
    const offset = Math.abs(timezoneOffset);
    const hours = Math.floor(offset / 60);
    const minutes = offset % 60;
    const formattedTime = `${sign}${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    return {
      timezone: timezone,
      timezoneFormat: `${timezone} (GMT ${formattedTime})`,
    };
  });

  const navigate = useNavigate();

  // organizerDetail:"{"id":"169ac09a-ea3d-4d53-8e84-95cc38d7d1ca","organizerName":"Vinay ","type":"an Individual","gstStatus":"Not Registered","state":"Uttarakhand","createdBy":"OWNER-000000002","userRole":1,"coverPhotoUrl":"uploads/profile_cover/profileCover-1708322523770.jpg","country":"India","gstNumber":2244}"

  let organizer = JSON.parse(localStorage.getItem("organizerDetail"));

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [tickets, setTickets] = useState([]);

  const [show, setShow] = useState(false);
  const [type, setType] = useState("add");
  const [current, setCurrent] = useState(null);

  const handleClose = () => {
    setType("add");
    setCurrent(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const addTicket = (data, type) => {
    if (type == "add") {
      setTickets((prev) => [
        ...prev,
        {
          id: generateUniqueId(),
          ...data,
        },
      ]);
    } else {
      setTickets([...data]);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (!file) {
        return setFileError(true);
      }
      const formData = new FormData();
      formData.append("eventName", values.eventName);
      formData.append("displayEventName", values.displayEventName);
      formData.append("startDate", values.startDate);
      formData.append("endDate", values.endDate);
      formData.append("startTime", values.startTime);
      formData.append("endTime", values.endTime);
      formData.append("isVisibility", values.isVisibility);
      formData.append("timeZone", values.timeZone);
      formData.append("venuName", values.venueName);
      formData.append("streetLane1", values.streetLane1);
      formData.append("streetLane2", values.streetLane2);
      formData.append("city", values.city);
      formData.append("longDescription", values.longDescription);
      formData.append("objectType", "DesktopCover");
      formData.append("desktopcover", file);
      formData.append("tickets", JSON.stringify(tickets));

      await Add_Event(formData, organizer?.createdBy);

      toast.success("Event Added");
      reset();
      setFile(null);
      setTickets([]);
      navigate("/Detailpage");
    } catch (error) {
      toast.error("Failed to add event");
    }
  };

  const handleRemove = (id) => {
    let tempTickets = [...tickets];
    tempTickets = tempTickets.filter((item) => item.id != id);
    setTickets([...tempTickets]);
  };

  return (
    <>
      <div className="content-body">
        <div className="add-event-container">
          <Row>
            <h1>Add event</h1>
          </Row>
          <Form className="add-event-form" onSubmit={handleSubmit(onSubmit)}>
            <Row className="my-2">
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicTitle">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Title
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Enter Title"
                    {...register("eventName", { required: true })}
                  />
                  {errors.eventName?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Title is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Display Event Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Display Event Name"
                    size="lg"
                    {...register("displayEventName", { required: true })}
                  />
                  {errors.displayEventName?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Display Name is required
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col md={12} lg={6} className="p-1">
                <Form.Group
                  className="mb-3"
                  controlId="formBasicLongDescription"
                >
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Long Description
                  </Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    placeholder="Enter Long Description"
                    size="lg"
                    {...register("longDescription", { required: true })}
                  />
                  {errors.longDescription?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Long Description is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1"></Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicIsVisibility">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    isVisibility
                  </Form.Label>
                  <Form.Select
                    size="lg"
                    {...register("isVisibility", { required: true })}
                  >
                    <option>Open this select menu</option>
                    <option value="public">public</option>
                    <option value="private">private</option>
                  </Form.Select>
                  {errors.isVisibility?.type === "required" && (
                    <p role="alert" className="text-danger">
                      IS Visibility is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicStartDate">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Start Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Start Date"
                    size="lg"
                    {...register("startDate", { required: true })}
                  />
                  {errors.startDate?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Start Date is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicStartTime">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Start Time
                  </Form.Label>
                  <Form.Control
                    type="time"
                    placeholder="Enter Start Time"
                    size="lg"
                    {...register("startTime", { required: true })}
                  />
                  {errors.startTime?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Start Time is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicEndDate">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    End Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter End Date"
                    size="lg"
                    {...register("endDate", { required: true })}
                  />
                  {errors.endDate?.type === "required" && (
                    <p role="alert" className="text-danger">
                      End Date is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicEndTime">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    End Time
                  </Form.Label>
                  <Form.Control
                    type="time"
                    placeholder="Enter End Time"
                    size="lg"
                    {...register("endTime", { required: true })}
                  />
                  {errors.endTime?.type === "required" && (
                    <p role="alert" className="text-danger">
                      End Time is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicTimeZone">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Time Zone
                  </Form.Label>
                  <Form.Select
                    size="lg"
                    {...register("timeZone", { required: true })}
                  >
                    <option>Open this select menu</option>
                    {timezones.map((timezone) => (
                      <option
                        value={timezone.timezoneFormat}
                        key={timezone.timezoneFormat}
                      >
                        {timezone.timezoneFormat}
                      </option>
                    ))}
                  </Form.Select>
                  {errors.timeZone?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Time Zone is required
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <h1>Location</h1>
            </Row>
            <Row>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicVenue">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Venue Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Venue Name"
                    size="lg"
                    {...register("venuName", { required: true })}
                  />
                  {errors.venuName?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Venue Name is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicLine1">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Lane 1
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Lane 1"
                    size="lg"
                    {...register("streetLane1", { required: true })}
                  />
                  {errors.streetLane1?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Lane 1 is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicLine2">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Lane 2
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Lane 2"
                    size="lg"
                    {...register("streetLane2", { required: true })}
                  />
                  {errors.streetLane1?.type === "required" && (
                    <p role="alert" className="text-danger">
                      Lane 2 is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={6} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicCity">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    City
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    size="lg"
                    {...register("city", { required: true })}
                  />
                  {errors.city?.type === "required" && (
                    <p role="alert" className="text-danger">
                      City is required
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="d-flex justify-content-between align-items-center">
                <h1>Tickets</h1>
                <Button onClick={handleShow}>Add ticket</Button>
              </Col>
            </Row>
            {tickets.map((ticket) => (
              <Row
                className="m-1 p-2"
                style={{
                  backgroundColor: "#f5f1ed",
                }}
                key={ticket.id}
              >
                <Col md={12} lg={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>
                        {ticket.ticketName}{" "}
                        <Badge bg="primary">{ticket.isTicketType}</Badge>{" "}
                      </Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">
                        {ticket.currencySymbol} {ticket.ticketPrice} (quantity :{" "}
                        {ticket.ticketquantity})
                      </Card.Subtitle>
                      <Card.Text>
                        Ticket sales starts from {ticket.startDate} and end on{" "}
                        {ticket.endDate}. {ticket.ticketDescription}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="d-flex ">
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        className="m-1 text-primary"
                        onClick={(e) => {
                          handleShow();
                          setType("edit");
                          setCurrent(ticket.id);
                        }}
                      >
                        Edit
                      </span>
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                        className="m-1 text-danger"
                        onClick={(e) => {
                          handleRemove(ticket.id);
                        }}
                      >
                        Remove
                      </span>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            ))}
            <Row className="mt-2">
              <h1>Media</h1>
            </Row>
            <Row className="mt-2">
              <Col md={12} lg={12} className="p-1">
                <Form.Group className="mb-3" controlId="formBasicImg">
                  <Form.Label
                    style={{
                      fontSize: ".95rem",
                      fontWeight: 700,
                    }}
                  >
                    Cover Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    placeholder="Enter Cover Image"
                    size="lg"
                    onChange={(e) => {
                      setFileError(false);
                      setFile(e.target.files[0]);
                    }}
                  />
                  {fileError && (
                    <p role="alert" className="text-danger">
                      Cover Image is required
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12} lg={12} className="p-1">
                <Button type="submit">Add Event</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <AddTicket
        setType={setType}
        type={type}
        addTicket={addTicket}
        show={show}
        handleClose={handleClose}
        editId={current}
        tickets={tickets}
      />
    </>
  );
};

export default AddEvent;
