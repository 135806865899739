// export const baseURL = "http://ec2-34-229-203-142.compute-1.amazonaws.com/runtv/v1"
export let environment = "dev";
let baseURL;

switch (environment) {
  case 'uat':
    baseURL = "http://13.232.109.90:8081/api";

   
    break;
  case 'dev':
    baseURL = "https://api.sportifi.in/api";
  
    break;
}
export const imagurl = "https://api.sportifi.in/"
export const Login_URL = `${baseURL}/authenticateadmin`;
export const Organizerlist_URL = `${baseURL}/admin/Organizer-Detail`;
export const DeleteOrganizer_list_URL = `${baseURL}/admin/Get-All-Deleted-user`;
export const restore_User_URL = `${baseURL}/admin/Restore-user/`;
export const OrganizerEvent_URL = `${baseURL}/admin/Organizer-event/`;
export const getSingleEvent_URL = `${baseURL}/get-SingleEvent/`;
export const updateEvent_URL = `${baseURL}/admin/Edit-Event/`;
export const totalSales_URL = `${baseURL}/admin/Total-Sales`;
export const totalEvent_URL = `${baseURL}/admin/Total-Event`;
export const totalOrganizer_URL = `${baseURL}/admin/Total-organizer`;
export const EventGst_URL = `${baseURL}/admin/Event-gst/`;
export const EditOrganizer_URL = `${baseURL}/admin/Edit-Organizer/`;
// export const imagurl = `http://13.232.109.90:8081/`;
export const addEvent_URL = `${baseURL}/admin/Admin-event-create/`;

export const getAllCountries_url =
  "https://countriesnow.space/api/v0.1/countries/currency";
  export const getCountryStates_url =
  "https://countriesnow.space/api/v0.1/countries/states";
export const  Create_User_URL = `${baseURL}/admin/Created-user`;
export const GET_USER_DATA = `${baseURL}/admin/User-Data`;
export const GET_USER_DATA_BY_ID = `${baseURL}/admin/Get-user/`;
export const Get_current_user = `${baseURL}/admin/Get-All-current-user`;
export const UPDATE_USER = `${baseURL}/admin/Edit-user/`;
export const DeleteUser_URL = `${baseURL}/admin/Delete-user/`;
export const KYC_Data_URL = `${baseURL}/admin/get-Kyc-data`;
export const GET_FM_URL = `${baseURL}/admin/Get-booking-charges`;
export const BookingCharges_URL = `${baseURL}/admin/Edit-Booking-charges/`;
export const GET_ALL_TICKETS = `${baseURL}/auth/get-tickets/`;
export const GET_ALL_Discount = `${baseURL}/discount/`;
export const Update_TicketDiscounts_URL = `${baseURL}/auth/discount/`;
export const Create_TicketDiscounts_URL = `${baseURL}/auth/discount`;
export const Update_Ticket_URL = `${baseURL}/auth/edit-ticket-detail/`;
export const Create_Ticket_URL = `${baseURL}/auth/create-ticket/`;
export const Delete_Ticket_URL = `${baseURL}/auth/ticket/`;
export const BookingChar = `${baseURL}/admin/Booking-charges`;
export const Downloaduserdata = `${baseURL}/admin/Download-user-data-excel`;
export const updateBooking_Charges = `${baseURL}/admin/Edit-Booking-charges/`;
export const AddBooking_Charges = `${baseURL}/admin/Add-booking-charges`;


