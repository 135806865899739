import { Button } from '@mantine/core';
import React, { useEffect } from 'react'
import {  Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';

const AddTicket = ({
    show, handleClose, addTicket, type, setType, editId, tickets
}) => {

    const currentlyEditing = type == "edit" && tickets.find(item => item.id == editId)

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
        setValue
    } = useForm({
        defaultValues: {
            ticketName: "",
            isTicketType: "paid",
        }
    });

    useEffect(() => {
        if (currentlyEditing) {
            setValue("isTicketType", currentlyEditing.isTicketType)
            setValue("ticketName", currentlyEditing.ticketName)
            setValue("ticketquantity", currentlyEditing.ticketquantity)
            setValue("ticketPrice", currentlyEditing.ticketPrice)
            setValue("startDate", currentlyEditing.startDate)
            setValue("startTime", currentlyEditing.startTime)
            setValue("endDate", currentlyEditing.endDate)
            setValue("endTime", currentlyEditing.endTime)
            setValue("minBooking", currentlyEditing.minBooking)
            setValue("maxBooking", currentlyEditing.maxBooking)
            setValue("ticketDescription", currentlyEditing.ticketDescription)
            setValue("attendeeMessage", currentlyEditing.attendeeMessage)
            setValue("currencySymbol", currentlyEditing.currencySymbol)
        }
    }, [type])

    const createTicket = (values) => {
        if (type === "edit") return editTicket(values)
        addTicket(values, type)
        handleClose()
        reset()
    }

    const editTicket = (values) => {
        let tempTickets = [...tickets];
        tempTickets = tempTickets.filter((item) => item.id != currentlyEditing.id);
        tempTickets.push({
            ...values,
            id: currentlyEditing.id
        })
        addTicket(tempTickets, type)
        reset()
        setType("add")
        handleClose()
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size='lg' centered >
                <Modal.Body>
                    <Container className='add-ticket' style={{
                        maxHeight: "70vh",
                        overflowX: "hidden",
                        overflowY: "scroll"
                    }} >
                        <Form onSubmit={handleSubmit(createTicket)} >
                            <Row>
                                <Col >
                                    <h2>Add Ticket</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket Name
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Ticket Name"
                                            size="lg"
                                            {...register("ticketName", { required: true })}
                                        />
                                        {errors.ticketName?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket Name is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket Quantity
                                        </Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Ticket Quantity"
                                            size="lg"
                                            {...register("ticketquantity", { required: true })}
                                        />
                                        {errors.ticketquantity?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket Quantity is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket sale starts from
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Ticket start date"
                                            size="lg"
                                            {...register("startDate", { required: true })}
                                        />
                                        {errors.startDate?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket sale starts from is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket sale starts time
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            placeholder="Enter   Ticket sale starts time"
                                            size="lg"
                                            {...register("startTime", { required: true })}
                                        />
                                        {errors.startTime?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket sale starts time is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket sale ends on
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Ticket end date"
                                            size="lg"
                                            {...register("endDate", { required: true })}
                                        />
                                        {errors.endDate?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket sale starts from is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket sale ends time
                                        </Form.Label>
                                        <Form.Control
                                            type="time"
                                            placeholder="Enter  Ticket sale ends time"
                                            size="lg"
                                            {...register("endTime", { required: true })}
                                        />
                                        {errors.endTime?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket sale ends time is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket Type
                                        </Form.Label>
                                        <Form.Select
                                            size="lg"
                                            {...register("isTicketType", { required: true })}
                                        >
                                            <option>Open this select menu</option>
                                            <option value="Paid">Paid</option>
                                            <option value="Free">Free</option>
                                        </Form.Select>
                                        {errors.isTicketType?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket Type is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                watch("isTicketType") == "Paid" ? (
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicVenue">
                                                    <Form.Label
                                                        style={{
                                                            fontSize: ".95rem",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Currency Symbol
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Enter Currency Symbol"
                                                        size="lg"
                                                        {...register("currencySymbol", { required: watch("isTicketType") == "Paid" })}
                                                    />
                                                    {errors.currencySymbol?.type === "required" && (
                                                        <p role="alert" className="text-danger">
                                                            Currency Symbol is required
                                                        </p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicVenue">
                                                    <Form.Label
                                                        style={{
                                                            fontSize: ".95rem",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Ticket Price
                                                    </Form.Label>
                                                    <Form.Control
                                                        type='number'
                                                        placeholder="Enter Ticket Price"
                                                        size="lg"
                                                        {...register("ticketPrice", { required: watch("isTicketType") == "Paid" })}
                                                    />
                                                    {errors.ticketPrice?.type === "required" && (
                                                        <p role="alert" className="text-danger">
                                                            Ticket Price is required
                                                        </p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicVenue">
                                                    <Form.Label
                                                        style={{
                                                            fontSize: ".95rem",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Who will pay sporti fee
                                                    </Form.Label>
                                                    <Form.Select
                                                        size="lg"
                                                        {...register("payFee", { required: watch("isTicketType") == "Paid" })}
                                                    >
                                                        <option>Open this select menu</option>
                                                        <option value="Me">Me</option>
                                                        <option value="Buyer">Buyer</option>
                                                    </Form.Select>
                                                    {errors.payFee?.type === "required" && (
                                                        <p role="alert" className="text-danger">
                                                            Who will pay sporti fee required
                                                        </p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" controlId="formBasicVenue">
                                                    <Form.Label
                                                        style={{
                                                            fontSize: ".95rem",
                                                            fontWeight: 700,
                                                        }}
                                                    >
                                                        Who will pay Payment Gateway fee
                                                    </Form.Label>
                                                    <Form.Select
                                                        size="lg"
                                                        {...register("paymentFee", { required: watch("isTicketType") == "Paid" })}
                                                    >
                                                        <option>Open this select menu</option>
                                                        <option value="Me">Me</option>
                                                        <option value="Buyer">Buyer</option>
                                                    </Form.Select>
                                                    {errors.paymentFee?.type === "required" && (
                                                        <p role="alert" className="text-danger">
                                                            Who will pay Payment Gateway fee required
                                                        </p>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>

                                    </>
                                )
                            }
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Min Booking
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder="Enter Min Booking"
                                            size="lg"
                                            {...register("minBooking", { required: true })}
                                        />
                                        {errors.minBooking?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Min Booking is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Max Booking
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder="Enter Max Booking"
                                            size="lg"
                                            {...register("maxBooking", { required: true })}
                                        />
                                        {errors.maxBooking?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Max Booking is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Ticket Description

                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter Ticket Description"
                                            size="lg"
                                            {...register("ticketDescription")}
                                        />
                                        {errors.ticketDescription?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Ticket Description is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicVenue">
                                        <Form.Label
                                            style={{
                                                fontSize: ".95rem",
                                                fontWeight: 700,
                                            }}
                                        >
                                            Attendee Message

                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter Attendee Message"
                                            size="lg"
                                            {...register("attendeeMessage")}
                                        />
                                        {errors.attendeeMessage?.type === "required" && (
                                            <p role="alert" className="text-danger">
                                                Attendee Message is required
                                            </p>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='d-flex flex-row-reverse align-items-center' >
                                    <Button variant="outline" color="red" className='mx-2 px-4 py-1' onClick={() => {
                                        reset()
                                        handleClose()
                                    }} >Canel</Button>
                                    <Button className='mx-2 px-4 py-1' type='submit' >Save</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddTicket