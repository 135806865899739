// src/components/BookingCharges.js
import React, { useState, useEffect } from "react";
import { Button } from "@mantine/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CountUp from "react-countup";
import { BookingCharge } from "../../api/api";
import { toast } from "react-toastify";

const BookingCharges = () => {
  const [bstartDate, setbStartDate] = useState(null);
  const [bendDate, setbEndDate] = useState(null);
  const [bookingCh, setBookingCh] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const formatDate = (date) => {
    if (!date) return undefined;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
useEffect(() => {
  booking();
}, []);
  const booking = async () => {
    let queryParams = {
      startDate: formatDate(bstartDate),
      endDate: formatDate(bendDate),
    };
    setShowLoader(true);
    try {
      const res = await BookingCharge(queryParams);
      if (res?.status === 200) {
        setBookingCh(res?.data);
      } else {
        toast.error("Failed to fetch booking charges.");
      }
    } catch (error) {
      console.error("Error fetching booking charges:", error);
      toast.error("An error occurred while fetching booking charges.");
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <div className="border h-[200px] my-3 flex justify-center border-gray-200">
      <div className="top-content items-center">
        <div className="left-side my-2">
          <div className="text-5xl text-gray-600 font-bold">
            <CountUp end={bookingCh !== 0 && bookingCh} duration={2.5} />
          </div>
        </div>
        <div className="right-side">
          <div className="date-filters flex gap-2">
            <DatePicker
              selected={bstartDate}
              onChange={(date) => setbStartDate(date)}
              placeholderText="Start Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={bendDate || new Date()}
            />
            <DatePicker
              selected={bendDate}
              onChange={(date) => setbEndDate(date)}
              placeholderText="End Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              minDate={bstartDate}
              maxDate={new Date()}
            />
            <Button
              variant="outline"
              color="#0b3d6a"
              onClick={() => booking()}
            >
              Apply Date Filter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingCharges;
