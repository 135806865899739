import React, { useState, useEffect } from 'react';
import "../../assets/css/style.scss";
import "./OrganizerListing.scss";
import SearchBar from '../../component/search-bar/searchBar';
import { EditOrganizer, Organizer_list } from '../../api/api';
import EditModal from '../../component/organizerPopup/organizerpop';
import ConfirmModal from '../../component/confirmDelete';
import { IconButton } from '@mui/material';
import { Delete, Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import CustomTable from '../../component/Tables/CustomTable';

const PAGE_SIZE = 10;

const fetchOrganizers = async (searchText, currentPage, pageSize, setOrganizerList, setNumberOfElements) => {
  try {
    const response = await Organizer_list({
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize
    });
    if (response?.status === 200) {
      setOrganizerList(response?.data.rows);
      setNumberOfElements(response?.data.count);
    }
  } catch (error) {
    console.error('Failed to fetch organizers:', error);
  }
};

const OrganizerListing = () => {
  const [searchText, setSearchText] = useState("");
  const [organizerList, setOrganizerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [totalElements, setTotalElements] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [singleData, setSingleData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const columns = [
    { accessorKey: "organizerName", header: "Organizer Name" },
    { accessorKey: "type", header: "Type" },
    { accessorKey: "state", header: "State" },
    { accessorKey: "gstStatus", header: "GST Status" },
    {
      accessorKey: "actions",
      header: "Action",
      cell: ({ row }) => (
        <div className='icon-group flex'>
          <IconButton
            className="table-icon cursor-pointer"
            onClick={() => {
              localStorage.setItem("organizerDetail", JSON.stringify(row));
              window.location.href = "/detailpage";
            }}
          >
            <RemoveRedEyeOutlined fontSize="medium" />
          </IconButton>
          <IconButton
            className="table-icon mx-3 cursor-pointer"
            onClick={() => {
              setSingleData(row);
              setModalOpen(true);
            }}
          >
            <Edit fontSize="medium" />
          </IconButton>
          <IconButton
            className="table-icon cursor-pointer"
            onClick={() => openDeleteModal(row.id)}
          >
            <Delete fontSize="medium" />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      await fetchOrganizers(searchText, currentPage, rowsPerPage, setOrganizerList, setTotalElements);
      setShowLoader(false);
    };
    fetchData();
  }, [currentPage, rowsPerPage, searchText]);

  // useEffect(() => {
  //   setTotalPages(Math.ceil(totalElements / rowsPerPage));
  // }, [totalElements, rowsPerPage]);

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleCloseModal = () => setModalOpen(false);
  
  const handleSubmit = async (formData) => {
    try {
      const updatedData = await EditOrganizer(formData.createdBy, formData);
      if (updatedData?.status === 200) {
        setModalOpen(false);
        handleSearch(searchText);
      }
    } catch (error) {
      console.error('Failed to update organizer:', error);
    }
  };

  const openDeleteModal = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      // Assuming deleteorginazer is defined elsewhere
      // await deleteorginazer(itemToDelete);
      setShowDeleteModal(false);
      handleSearch(searchText);
    }
  };

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content text-gray-500 text-2xl font-medium my-2">
          Organizers
        </div>
        <div className='organizer-setup-header my-2'>
          <EditModal
            isOpen={modalOpen}
            onClose={handleCloseModal}
            singledata={singleData}
            onSubmit={handleSubmit}
          />
        </div>
        <div className='organizer-setup-table'>
          <CustomTable
            btnName={"Add Organizer"}
            rows={organizerList}
            columns={columns}
            handleAdd={() => setModalOpen(true)} 
            count={totalElements}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={currentPage}
            rowsPerPage={rowsPerPage}
            filterText={searchText}
            setFilterText={setSearchText}
          />
        </div>
        {showDeleteModal && (
          <ConfirmModal
            show={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            handleConfirm={confirmDelete}
            deleteDataName={"Organizer"}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizerListing;
