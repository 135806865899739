import React from 'react'
import { Tabs, TextInput, Button, Select } from "@mantine/core";
import Map from "../Map";
import EditorConvertToHTML from "./EditorConvertToHTML";
import delteicon from "../../assets/images/deleteicon.png";
import { imagurl } from '../../service/Api_URls';




function Eventedit({form , editMode ,editorValue,longDescription,setLongDescription,shortDescription,    setShortDescription,imageShow,delete_img,handleFileChange,handleEdit,handleUpdate     }) {
  return (
    <div cl>
                        
    <Tabs className="scroll-height py-4 px-2 editTabs" defaultValue="Basics" >
        <Tabs.List className="tablist">
            <Tabs.Tab value="Basics">Basics</Tabs.Tab>
            <Tabs.Tab value="Location">Location</Tabs.Tab>
            <Tabs.Tab value="Description">Description</Tabs.Tab>
            <Tabs.Tab value="Photos">Photos</Tabs.Tab>
            <Tabs.Tab value="Type & Tags">Type & Tags</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="Basics" pt="xs">
            <div className="expand-content w-100 row p-3">
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Title<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("eventName")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Display Event Name<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("displayEventName")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Event URL<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("eventUrl")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        isVisibility<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("isVisibility")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Start Date<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("startDate")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Start Time<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("startTime")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        End Date<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("endDate")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        End Time<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("endTime")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Currency Symbol<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("currencySymbol")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Status<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("eventStatus")}
                        disabled={!editMode}
                    />
                </div>
         


                <div className="detail-row col-6">
                <div className="detail-label">
                    isLive<span style={{ color: "red" }}>*</span>
                </div>
                <Select
                   
                    placeholder="select"
                    data={[
                        { value: 'true', label: 'True' },
                        { value: 'false', label: 'False' }
                    ]}
                    value={form.values.isLive.toString()}
                    onChange={(value) => form.setFieldValue('isLive', value === 'true')}
                    disabled={!editMode}
                    clearable={false}
                />
            </div>



                <div className="detail-row col-6">
                    <div className="detail-label">
                        Time Zone<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("timeZone")}
                        disabled={!editMode}
                    />
                </div>
            </div>
        </Tabs.Panel>
        <Tabs.Panel value="Location" pt="xs">
            <div className="expand-content w-100 row p-3">
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Venue Name<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("venueName")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        City<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("city")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Latitude<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("latitude")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Longitude<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("longitude")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Street Lane 1<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("streetLane1")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Street Lane 2<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("streetLane2")}
                        disabled={!editMode}
                    />
                </div>
                <div className="col-span-7 p-8 h-[500px]">
                    <Map lat={form?.values?.latitude} lng={form?.values?.longitude} />
                </div>
            </div>
        </Tabs.Panel>
        <Tabs.Panel value="Description" pt="xs">
            <div className="expand-content w-100 row p-3">

                <h4 className="detail-label">
                    Long Description <span style={{ color: "red" }}>*</span>
                </h4>
                <div className="bg-white p-3">
                    <EditorConvertToHTML
                        editor={editorValue}
                        value={longDescription}
                        onChange={setLongDescription}
                        readOnly={!editMode}
                    />
                </div>


                <h4 className="detail-label mt-4">
                    Short Description<span style={{ color: "red" }}> *</span>
                </h4>
                <div className="bg-white p-3">
                    <EditorConvertToHTML
                        editor={editorValue}
                        value={shortDescription}
                        onChange={setShortDescription}
                        readOnly={!editMode}
                    />

                </div>
            </div>

        </Tabs.Panel>
        <Tabs.Panel value="Photos" pt="xs">
            <div className="detail-row ">
                <div className="detail-label">Cover Image</div>
                <div className={imageShow ? "imageexist" : "box__input"}>
                    {imageShow ? (
                        <>
                            {editMode && (
                                <a>
                                    <img
                                        onClick={() => delete_img()}
                                        src={delteicon}
                                    />
                                </a>
                            )}

                            {typeof imageShow === "string" &&
                                imageShow.startsWith("uploads")
                                ? imageShow && (
                                    <img
                                        className="image-show"
                                        src={imagurl + imageShow}
                                        alt="Uploaded"
                                    />
                                )
                                : imageShow && (
                                    <img
                                        className="image-show"
                                        src={imageShow}
                                        alt="Uploaded"
                                    />
                                )}
                        </>
                    ) : (
                        <>
                            <input
                                type="file"
                                className="inputFile"
                                name="file"
                                // onChange={(e) => {
                                //   validateFileSize(e, imagetype, imgcategory, icontype);
                                // }}
                                onChange={handleFileChange}
                                disabled={!editMode}
                            />
                            <div className="box-label">{"label"}</div>
                            <div className="box-size">{"size"}</div>
                            {/* { imagepath && <img src={imagepath} alt="Uploaded" />} */}
                        </>
                    )}
                    {/* Display the image if uploaded */}
                </div>
            </div>
        </Tabs.Panel>
       
        <Tabs.Panel value="Type & Tags" pt="xs">
            <div className="expand-content w-100 row p-3">
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Event Type<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("eventType")}
                        disabled={!editMode}
                    />
                </div>
                <div className="detail-row col-6">
                    <div className="detail-label">
                        Tags<span style={{ color: "red" }}>*</span>
                    </div>
                    <TextInput
                        className=""
                        {...form.getInputProps("tags")}
                        disabled={!editMode}
                    />
                </div>
            </div>
        </Tabs.Panel>
    </Tabs>

    <div className="button-container ">
        {editMode ? (
            <Button onClick={handleUpdate} >
                Update Details
            </Button>
        ) : (
            <Button onClick={handleEdit} >
                Edit Details
            </Button>
        )}
    </div>
</div>
  )
}

export default Eventedit