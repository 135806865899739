import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./organizer.scss"
import { Button } from '@mantine/core';
const EditModal = ({ isOpen, onClose, singledata, onSubmit }) => {
    const [formData, setFormData] = useState(singledata);




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {



        onSubmit(formData);
        onClose(); // Close the modal after submitting
    };
    useEffect(() => {
        setFormData(singledata)
    }, [singledata])
    return (
        <Modal show={isOpen} onHide={onClose} centered>
            {/* <Modal.Header closeButton className="ModalHeader">
               
            </Modal.Header> */}
            <Modal.Body className="ModalBody">

                <Modal.Title className="ModalTitle">Edit Organizer</Modal.Title>

                <Form >
                    <Row className='my-3'>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Organizer Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="organizerName"
                                    value={formData?.organizerName}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Country</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="country"
                                    value={formData?.country}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="type"
                                    value={formData?.type}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>GST Status</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gstStatus"
                                    value={formData?.gstStatus}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="state"
                                    value={formData?.state}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>GST Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gstNumber"
                                    value={formData?.gstNumber}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className='flex gap-3'>
                    <Button color='#0b3d6a'  onClick={() => handleSubmit(formData)}>
                        Submit
                    </Button>
                    <Button color='#0b3d6a' variant='outline' onClick={() => onClose()} type="button">
                        Cancel
                    </Button>
                    </div>
                
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditModal;
