import React, { useEffect, useState } from "react";
import CustomTable from "../Tables/CustomTable";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Switch } from "@mantine/core";
import DiscountForm from "./DiscountForm";
import { Create_TicketDiscounts, Delete_TicketDiscount, GetDiscount, GetTickets, Update_TicketDiscounts } from "../../api/api";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ConfirmModal from "../confirmDelete";
import NcustomTable from "../Tables/NcustomTable";

function Discount({ item }) {
  const [data, setData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [allTickets, setAllTickets] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  useEffect(() => {
    if (item?.id){
      DiscountDetail(item.id);
      GetTicketsData(item.id);
    }
  }, [item?.id]);

  const columns = [
    { accessorKey: "code", header: "Code" },
    { accessorKey: "type", header: "Type" },
    { accessorKey: "noOfDiscounts", header: "No of Discounts" },
    { accessorKey: "discountPercent", header: "Discount Percent" },
    {
      accessorKey: "endDate",
      header: "Discount Expiry",
      cell: ({ value }) => {
        return moment(value).format("DD/MM/YYYY, hh:mm a");
      },
    },
    {
      accessorKey: "",
      header: "Action",
      cell: ({ row }) => {
        return (
          <div>
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              size="medium"
            >
              <Edit fontSize="large" />
            </IconButton>
            <IconButton
              onClick={() => openDeleteModal(row?.id)}
              aria-label="delete"
              size="medium"
            >
              <Delete fontSize="large" />
            </IconButton>
            <IconButton aria-label="tag" size="medium">
              <Switch
                checked={
                  row.type === "CODE"
                    ? row.status
                    : row.TicketDiscounts[0]?.CreateTicket?.isFlatDiscount
                }
                onChange={(event) =>
                  onStatusChange(event.currentTarget.checked, row?.id, row)
                }
              />
            </IconButton>
          </div>
        );
      },
    },
  ];
  const GetTicketsData = async (id) => {
    try {
      const ticketResponse = await GetTickets(id);
      if (ticketResponse.status == 200) {
        setAllTickets(ticketResponse.data);
      } else {
        toast.error("Failed to fetch tickets", { toastId: "fetch-tickets-error" });
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      toast.error("Failed to fetch tickets", { toastId: "fetch-tickets-error" });
    }
  };

  const DiscountDetail = async (id) => {
    try {
      const discountResponse = await GetDiscount(id);
      if (discountResponse.status === 200) {
        setData(discountResponse.data);
      } else {
        toast.error("Failed to fetch discount details", { toastId: "fetch-discount-error" });
      }
    } catch (error) {
      console.error("Error fetching discount details:", error);
      toast.error("Failed to fetch discount details", { toastId: "fetch-discount-error" });
    }
  };

  const UpdateTicketStatus = async (id, payload) => {
    try {
      const updateStatus = await Update_TicketDiscounts(id, payload);
      if (updateStatus.status === 200) {
        toast.success(updateStatus.data.data || "Discount updated successfully", { toastId: "update-discount-success" });
        DiscountDetail(item.id);
      } else {
        toast.error(updateStatus.data.error.message || "Failed to update discount", { toastId: "update-discount-error" });
      }
    } catch (error) {
      console.error("Error updating discount:", error);
      toast.error("Failed to update discount", { toastId: "update-discount-error" });
    }
  };

  const CreateTicketStatus = async (payload) => {
    try {
      const createStatus = await Create_TicketDiscounts(payload);
      if (createStatus.status === 200) {
        toast.success(createStatus.data.data || "Discount created successfully", { toastId: "create-discount-success" });
        DiscountDetail(item.id);
      } else {
        toast.error(createStatus.data.error.message || "Failed to create discount", { toastId: "create-discount-error" });
      }
    } catch (error) {
      console.error("Error creating discount:", error);
      toast.error("Failed to create discount", { toastId: "create-discount-error" });
    }
  };

  const onStatusChange = (value, id, code) => {
    let updatedPayload = {};
    if (code.type === "CODE") {
      updatedPayload = {
        payload: {
          tickets: [],
          updatePayload: {
            status: value,
          },
        },
        id: id,
      };
    } else if (code.type === "FLAT") {
      const ticketIds = code.TicketDiscounts.map((item) => ({
        id: item.ticketId,
      }));
      updatedPayload = {
        payload: {
          tickets: ticketIds,
          updatePayload: {
            isFlatDiscount: value,
          },
        },
        id: id,
      };
    }
    UpdateTicketStatus(updatedPayload.id, updatedPayload.payload);
  };

  const openDeleteModal = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      await handleDelete(itemToDelete); // Call delete function
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };

  const handleDelete = async (id) => {
    try {
      const deleteResponse = await Delete_TicketDiscount(id);
      if (deleteResponse?.status === 200) {
        toast.success("Discount deleted successfully", { toastId: "delete-discount-success" });
        DiscountDetail(item.id);
      } else {
        toast.error("Failed to delete discount", { toastId: "delete-discount-error" });
      }
    } catch (error) {
      console.error("Error deleting discount:", error);
      toast.error("Failed to delete discount", { toastId: "delete-discount-error" });
    }
  };



  const handleSave = (id, data) => {
    UpdateTicketStatus(id, data);
  };

  const handleEdit = (row) => {
    setDrawerOpen(true);
    setIsEdit(true);
    setEditData(row);
  };

  const handleAdd = () => {
    setIsEdit(false);
    setDrawerOpen(true);
    setEditData(null);
  };

  const handleCreateDiscount = (data) => {
    CreateTicketStatus(data);
  };

  return (
    <div className="p-3">
      <DiscountForm
        isEdit={isEdit}
        isOpen={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        ticketData={editData}
        id={item?.id}
        onSave={isEdit ? handleSave : handleCreateDiscount}
        allTickets={allTickets}
      />
      <NcustomTable btnName={"Add Discount"} rows={data} columns={columns} handleAdd={handleAdd} />
    
      {showDeleteModal && (
        <ConfirmModal
          show={showDeleteModal}
          handleClose={closeDeleteModal}
          handleConfirm={confirmDelete}
          deleteDataName={"Discount"}
        />
      )}
    </div>
  );
}

export default Discount;
