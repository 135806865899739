import React, { useState, useEffect } from "react";
import SearchBar from "../../component/search-bar/searchBar";
import {
  Delete_User,
  Download_userdata,
  GET_USERDATA,
  GetcurrentUser,
  Organizer_list,
} from "../../api/api";
import "../../assets/css/style.scss";
import "./UserList.scss";
import { Link, useNavigate } from "react-router-dom";
import { getData } from "../../api/apiMethod";
import { Downloaduserdata, GET_USER_DATA } from "../../service/Api_URls";
import { getToken } from "../../utility/fnx";
import EYE_ICON from "../../assets/images/eye.svg";
import editIcon from "../../assets/images/edit-outline.svg";
import deleteIcon from "../../assets/images/delete_forever.png";
import CustomTable from "../../component/Tables/CustomTable";
import ConfirmModal from "../../component/confirmDelete";
import { IconButton } from "@mui/material";
import { Button, Drawer } from "@mantine/core";
import InviteUser from "./InviteUser";
import BookingCharges from "./Bookingcharges";
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import DatePicker from "react-datepicker";

const PAGE_SIZE = 10;

const NewUser = () => {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [drawerContentId, setDrawerContentId] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [orginazerList, setOrginazerList] = useState([]);
  const [numberOfElement, setNumberOfElement] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const navigate = useNavigate();

  const formatDate = (date) => {
    if (!date) return undefined;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const columns = [
    {
      accessorKey: "Profile",
      header: "Organizer Name",
      cell: ({ value }) => <span>{value?.fullName}</span>,
    },
    { accessorKey: "emailId", header: "Email" },
    {
      accessorKey: "Profile",
      header: "Country",
      cell: ({ value }) => <span>{value?.country}</span>,
    },
    {
      accessorKey: "Profile",
      header: "State",
      cell: ({ value }) => <span>{value?.state}</span>,
    },
    {
      accessorKey: "Profile",
      header: "Phone",
      cell: ({ value }) => <span>{value?.phone}</span>,
    },
    {
      accessorKey: "Profile",
      header: "GST Registration Status",
      cell: ({ value }) => <span>{value?.gstStatus}</span>,
    },
    {
      accessorKey: "actions",
      header: "Action",
      cell: ({ row }) => (
        <div className="icon-group flex gap-1">
          <IconButton onClick={() => handleEdit(row?.id)}>
            <RemoveRedEyeOutlined fontSize="medium" />
          </IconButton>
        </div>
      ),
    },
  ];

  const openDeleteModal = (item) => {
    setItemToDelete(item);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete?.createdBy);
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };

  // const fetchData = async () => {
  //   let queryParams = {
  //     name: searchText,
  //     pageNumber: currentPage + 1,
  //     pageSize: rowsPerPage,
  //     startDate: formatDate(startDate),
  //     endDate: formatDate(endDate),
  //   };
  //   await getOrginazer(queryParams);
  // };

  useEffect(() => {
    getUserData();
  }, [currentPage, searchText, startDate, endDate, rowsPerPage]);

  // const getOrginazer = async (queryParams) => {
  //   setShowLoader(true);
  //   const orginazerResponse = await Organizer_list(queryParams);
  //   if (orginazerResponse?.status === 200) {
  //     setOrginazerList(orginazerResponse?.data?.rows);
  //     setNumberOfElement(orginazerResponse?.data?.count);
  //   }
  //   setShowLoader(false);
  // };

  const Downloaduser = async () => {
    let queryParams = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    setShowLoader(true);
    try {
      const res = await Download_userdata(queryParams);
      if (res.status == 500) {
        const errorMessage = "An unknown error occurred.";
        console.error("Download error:", errorMessage);
        toast.error(`An error occurred while downloading the data: ${errorMessage}`);
      } else {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const today = new Date();
        const formattedDate = today.toLocaleDateString().replace(/\//g, '-');
        const filename = `user-data_${formattedDate}`;
        saveAs(blob, filename);
        toast.success("Your data has been downloaded successfully. Please check your download folder.");
      }
    } catch (error) {
      console.error("Error during download:", error);
      toast.error("An error occurred while processing your request. Please try again later.");
    } finally {
      setShowLoader(false);
    }
  };

  const handleEdit = (id) => {
    setDrawerContentId(id);
    setDrawerOpened(true);
  };

  const handleDelete = async (id) => {
    const DeleteUser = await Delete_User(id);
    if (DeleteUser?.status === 200) {
      await getUserData();
    } else {
      alert(DeleteUser?.error);
    }
  };

  const getUserData = async () => {
    let queryParams = {
      name: searchText,
      pageNumber: currentPage + 1,
      pageSize: rowsPerPage,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
    };
    try {
      const res = await GetcurrentUser(queryParams);
      if (res?.success) setData(res.data?.rows);
      setNumberOfElement(res?.data?.count);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    (async () => {
      await getUserData();
    })();
  }, []);

  const handleDateFilter = () => {
    setCurrentPage(0);
    getUserData();
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page when changing rows per page
  };

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <BookingCharges />
        <div className="top-content">
          <div className="left-side my-2 text-gray-500 text-2xl font-medium">
            <div>Users</div>
          </div>
          <div className="right-side flex gap-2">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              maxDate={endDate || new Date()}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              dateFormat="yyyy-MM-dd"
              className="form-control"
              minDate={startDate}
              maxDate={new Date()}
            />
            <Button variant="outline" color="#0b3d6a" onClick={handleDateFilter}>
              Apply Date Filter
            </Button>
          </div>
        </div>
        <CustomTable
          btnName="Download User Data"
          handleAdd={Downloaduser}
          isLoading={showLoader}
          rows={data}
          columns={columns}
          count={numberOfElement}
          page={currentPage}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          filterText={searchText}
          setFilterText={setSearchText}
        />
        <Drawer
          opened={drawerOpened}
          onClose={() => setDrawerOpened(false)}
          title="User Details"
          padding="xl"
          size="xl"
        >
          <InviteUser userId={drawerContentId} />
        </Drawer>
        <ConfirmModal
          open={showDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={confirmDelete}
        />
      </div>
    </div>
  );
};

export default NewUser;
