import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { GOOGLE_API_KEY } from "../../utility/deviceid";

const Map = ({ lat, lng, zoom = 11 }) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  // Update center when lat or lng props change
  useEffect(() => {
    setCenter({ lat, lng });
  }, [lat, lng]);



  return (
    <div style={{ height: '500px', width: '100%' }}>
        {center?.lat != 0 &&
        
        
        <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={{ lat: 0, lng: 0 }} // Initial center, can be overridden by props
        defaultZoom={zoom}
        center={center} // Center from state
        zoom={zoom} // Zoom level from props
      />
        
        }
      
    </div>
  );
};

export default Map;
