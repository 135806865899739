import React, { useState, useEffect, useCallback } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorConvertToHTML = ({ value, onChange, readOnly,editor }) => {
  const [editorState, setEditorState] = useState(() => {
    if (value) {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        console.log('Initial Content State:', contentState);
        return EditorState.createWithContent(contentState);
      }
    }
    return EditorState.createEmpty();
  });

  const onEditorStateChange = useCallback((editorState) => {
    setEditorState(editorState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    console.log('Raw Content State:', rawContentState);
    const html = draftToHtml(rawContentState);
    console.log('Converted HTML:', html);
    onChange(html);
  }, [onChange]);

  useEffect(() => {
    
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        // console.log('Updated Content State:', contentState);
        setEditorState(EditorState.createWithContent(contentState));
      }

  }, [editor]);

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        readOnly={readOnly}
        toolbarHidden={readOnly}
      />
      {/* Debugging textarea */}
      {/* <textarea
        disabled
        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
    </div>
  );
};

export default EditorConvertToHTML;
